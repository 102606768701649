@import "../../../../../styles/partials/colors";

#category-select-dialog-title {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid $grey-mid;
  align-items: center;

  span {
    padding: 10px 15px;
  }

  ._close {
    border-left: 1px solid $grey-mid;
  }
}

#category-select-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border-top: 1px solid $grey-mid;

  span:not(._submit) {
    font-size: 16px;
    font-weight: 700;
    margin-right: 9px;
  }

  ._submit {
    padding-top: 5px;
  }
}

#category-select-dialog-message {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 40px 0;
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    font-size: 18px;

    li {

      a {
        color: $grey;
        
        &.active {
          color: $primary;
        }
      }
      
      &:not(:last-child) {
        margin-bottom: 30px;
      }

    }
  }
}

