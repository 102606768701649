/**
 * ==============================================
 * Experiment-Gooey Effect
 * Dot Shuttle
 * ==============================================
 */

$d: 3*$dotSpacing;

.dot-shuttle {
  position: relative;
  left: - $dotSpacing;

  @include dot($width: 12px, $height: 12px, $radius: 6px, $bgColor: $dotColorHSL, $color: transparent);

  margin: -1px 0;
  filter: blur(2px);

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;

    @include dot($width: 12px, $height: 12px, $radius: 6px, $bgColor: $dotColorHSL, $color: transparent);

    filter: blur(2px);
  }

  &::before {
    left: $dotSpacing;
    animation: dotShuttle 2s infinite ease-out;
  }

  &::after {
    left: 2*$dotSpacing;
  }
}

@keyframes dotShuttle {
  0%,
  50%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-$d);
  }

  75% {
    transform: translateX($d);
  }
}
