@import "../../styles/partials/fonts";
@import "../../styles/partials/colors";

.home-section {
  padding: 50px 0;
  border-bottom: 1px solid $grey-mid;
  background: $white;
  .ContentScroller_contentScroller__kuTrc.ContentScroller_typeAround__28b6Y .ContentScroller_main__1ooKg .ContentScroller_sideArrow__3WyM_.ContentScroller_arrowNext__H636_{
    @media screen and (max-width: 1199px) and (min-width: 480px) {
      background: #7c7c7c;
      border-radius: 50%;
      right: 0px !important;
    }
  }
  .ContentScroller_contentScroller__kuTrc.ContentScroller_typeAround__28b6Y .ContentScroller_main__1ooKg .ContentScroller_sideArrow__3WyM_.ContentScroller_arrowPrev__1sx_f{
    @media screen and (max-width: 1199px) and (min-width: 480px) {
      background: #7c7c7c;
      border-radius: 50%;
      left: 5px !important;
      z-index: 1;
    }
  }
  .top {
    padding-bottom: 30px;


    .title {
      // font-family: $fontSpecial;
      // font-size: 24px;
      font-family: "roboto";
      font-size: 20px;
      font-weight: bold;
    }
  }

  &.grey {
    background-color: $grey-light;
  }

  &.fullHeight {
    // min-height: 100vh;
    display: flex;
    align-items: center;
  }
}
