#artistCodePage{
  .action{
    button{
      border-radius: 20px;
      height: 40px;
      width: 100px;
      background: #fff;
    }
    .cart-btn{
      border: 1px solid #DEDEDE;

    }
    .saved-btn{
      border: 1px solid #212121;

    }
  }
  .customContainer{
    @media all and (max-width: 667px){
      padding: 0;
    }
  }
  .commonGidContainer{
    .gridItem{
      &.artistSideBar{
        @media all and (max-width: 991px){
          display: none;
        }
      }
    }
    &.typeBox .sectionContent .styled-input label input{
      width: 300px;
      max-width: 100%;
    }
  }

  .artistCodeSection{
    margin-bottom: 0;
  }
}
