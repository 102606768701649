@import "../../styles/partials/snippets";

.sliderWrap {
  width: 100%;
  .slides {
    overflow: hidden;
    position: relative;
    @media all and (max-width:991px){
      padding-bottom: 60% !important;
    }
  }
}
.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: 0px center;
  &:global(.__a) {
    z-index: 15;
    > div {
      opacity: 1;
      transition: opacity .3s ease;
    }
  }
  &:global(.__ar) {
    z-index: 15;
    > div {
      opacity: 0;
      transition: all .3s ease;
    }
  }
  &:global(.__r) {
    z-index: 20;
    > div {
      opacity: 0;
      // transition: all .3s ease;
    }
  }
}
.bannerNavContainer{
  padding: 30px 150px;
  background-color: #fff;
  @extend .ex-flex;
  @extend .ex-flex-wrap;
  margin: 0 -6px;
  @media all and (max-width: 1600px){
    padding: 30px 80px;
  }
  @media all and (max-width: 1199px){
    padding: 25px 30px;
  }
  @media all and (max-width: 991px){
    display: none;
  }
}
