@import "../../styles/partials/colors";

.art-from-followed {
  .popularItem {
    position: relative;
    .imageContainer {
      border-radius: 5px;
      overflow: hidden;
      // box-shadow: 0 3px 4px rgba(0, 0, 0, 0.19);
      vertical-align: top;
      @media all and (max-width: 991px) {
        // box-shadow: 0 3px 4px rgba(0, 0, 0, 0.07);
      }
      img {
        height: 300px;
        // max-height: 300px;
        object-fit: cover;
        object-position: center;
        max-width: none;
        vertical-align: top;
        min-width: 150px;
        max-width: 600px;
      }
      .lazy-placeholder-2 {
        background: #f5f5f5;
        position: relative;
        height: 300px;
        margin-right: 8px;
        width: fit-content;
        // width: 100%;
      }
    }
  }
}

.artTextContainer {
  justify-content: space-between;
  margin-top: 10px;
  padding: 0;
}

.artTextContainer .artTextItem h5 {
  font-family: "Lora";
  font-weight: bold;
  margin-bottom: 3px;
}

.artTextContainer .artTextItem span {
  font-size: 14px;
  color: #9a9a9a;
}
.artTextContainer .artTextItem span a {
  color: #9a9a9a;
}
.artTextContainer .artTextItem span a:hover {
  text-decoration: underline;
}
