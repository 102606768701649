@import "../../../../styles/partials/colors";
@import "../../../../styles/partials/fonts";

#delete-account{
    .commonFormSection {
        padding-top: 80px;
        margin-left: auto;
        margin-right: auto;
        width: 25%;
        @media all and (max-width: 1600px) {
            padding-top: 70px;
            width: 30%;
        }
        @media all and (max-width: 768px) {
            width: 90%;
          padding-top: 54px;
          margin-bottom: 15px;
          .customContainer {
            padding: 0;
          }
        }
        .contentCardItem {
          .title {
            display: flex;
          }
          .datePickerHide {
            display: none;
          }
          .typeTwoCol {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -15px;
            .styled-input {
              padding: 0 15px;
              width: 50%;
              @media all and (max-width: 667px) {
                width: 100%;
              }
            }
          }
        }
        .proceed-btn {
            font-family: "Roboto";
            border-radius: 20px;
            background-color: #984b48;
            border: none;
            color: #fff;
            padding: 8px 20px;
            font-size: 14px;
          }
      }
}