@import "../../../../styles/partials/colors";
@import "../../../../styles/partials/fonts";
@import "../../../../styles/partials/snippets";

//order page css
.order-page {
  font-family: "Roboto";
  .order-header {
    background-color: #fff;
    height: 70px;
    padding: 20px 32px;
    display: flex;
    justify-content: space-between;
    .left {
      font-size: 25px;
      font-weight: bold;
      padding: 5px;
    }
    .right {
      margin-right: 0;
      margin-left: auto;
      min-width: 30%;
      text-align: end;
      .total-price {
        margin-right: 25px;
        font-size: 16px;
        font-weight: bold;
      }
      .go-to-cart-btn {
        font-family: "Roboto";
        border-radius: 20px;
        background-color: #984b48;
        border: none;
        color: #fff;
        padding: 8px 20px;
        font-size: 14px;
      }
    }
  }
  .orders {
    padding-top: 20px;
    width: 50%;
    margin: auto;
    padding: 10px;
    // @media screen and (max-width: 1251px) {
    //   width: 100%;
    //   margin-left: 20%;
    //   margin-right: 20%;
    // }
    @media screen and (max-width: 1321px) {
      display: none;
    }
    table {
      width: 100%;
      background-color: #ffffff;
      padding: 10px;
      border-radius: 5px;
      margin-top: 10px;

      thead {
        border-bottom: 1px solid #f3f3f3;
        padding: 30px 20px 10px 20px;
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        color: #9a9a9a;
      }

      .art-desc {
        display: flex;
        // justify-content: space-around;
        text-transform: initial;
        img {
          height: 110px;
          width: 110px;
          border-radius: 5px;
          object-fit: scale-down;
        }
        .desc {
          margin-top: 40px;
          margin-left: 5px;
          text-transform: initial;

          .art-name {
            font-family: "Lora" !important;
            font-size: 16px;
            font-weight: bold;
          }
          .artist {
            font-size: 14px;
            color: #9a9a9a;
          }
        }
      }
    }
  }
  .order-status {
    // background-color: $grey;
    border-radius: 15px;
    // padding: 4px 7px;
    width: 100%;
    font-weight: bold;
  }
  .cancelled {
    color: red;
  }
  .date {
    line-height: 2rem;
    font-size: 12px;
  }
  .cancel-btn {
    background-color: black;
    border: none;
    color: #fff;
    border-radius: 15px;
    font-weight: bold;
    text-align: center;
    margin: auto;

    padding: 5px 10px;
    font-size: 12px;
  }
  td {
    padding: 10px 20px;
    @media screen and (max-width: 415px) {
      padding: 10px 5px;
    }
  }
  .responsive-orders {
    display: none;

    @media screen and (max-width: 1321px) {
      display: block;
      padding-top: 20px;
      width: 50%;
      margin: auto;
    }
    @media screen and (max-width: 780px) {
      width: unset;
      margin: 0 5%;
    }

    table {
      width: 100%;
      background-color: #ffffff;
      padding: 10px;
      border-radius: 5px;
      margin-top: 10px;
      thead {
        border-bottom: 1px solid #f3f3f3;
        padding: 30px 20px 10px 20px;
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        color: #9a9a9a;
      }
      .art-desc {
        display: flex;

        img {
          height: 110px;
          width: 110px;
          border-radius: 5px;
          object-fit: scale-down;
          @media screen and (max-width: 490px) {
            height: 90px;
            width: 80px;
          }
        }
        .desc {
          margin-top: 20px;
          margin-left: 8px;
          min-width: 13ch;
          overflow: hidden;
          text-overflow: ellipsis;
          height: fit-content;
          text-transform: initial;
          @media screen and (max-width: 399px) {
            margin-top: 10px;
          }
          .art-name {
            font-family: "Lora" !important;
            font-size: 16px;
            font-weight: bold;
            // width: 6ch;
            // overflow: hidden;
            // text-overflow: ellipsis;
            @media screen and (max-width: 490px) {
              font-size: 14px;
            }
          }
          .artist {
            font-size: 14px;
            color: #9a9a9a;
            font-style: italic;
          }
          .price {
            color: #9a9a9a;
            font-weight: bold;
          }
        }
      }
    }
  }
  .no-items {
    text-align: center;
    margin-top: 20px;
  }
}
