@import "../../styles/partials/colors";
@import "../../styles/partials/fonts";
.fancy-nav {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    padding: 10px 15px;
    border: 1px solid #EFEEEF;
    border-radius: 5px;
    margin:0 10px 10px 0;
    @media all and (max-width: 1600px){
      font-size: 14px;
    }
    @media all and (max-width: 667px){
      padding: 10px 10px;
      font-size: 12px;
      &:not(:last-of-type){
        margin-right: 15px;
      }
    }
  }

  &._off {

    li {
      position: relative;
     

      &.active {
        background-color:#212121;
        color:#EFEEEF;

        // &:after {
        //   content: "";
        //   position: absolute;
        //   width: 100%;
        //   left: 0;
        //   height: 2px;
        //   background: $primary;
        //   bottom: 0;
        // }
      }
    }
  }
}

.nav-indicator-rail {
  width: 100%;
  position: relative;
  height: 1px;
  background: $grey-mid;
  @media all and (max-width: 677px){
    display: none;
  }
  .nav-indicator {
    background: $primary;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    position: absolute;
  }
}

.common-category-list {
  padding: 24px 0 15px;
  @media all and (max-width: 677px){
    padding: 5px 0 15px;
  }
  button {
    min-width: 130px;
    padding: 9px 10px;
    margin-top: 5px;
    background: $white;
    border-radius: 17px;
    font-family: $fontMain;
    border: 1px solid $grey-mid;
    font-size: 14px;
    @media all and (max-width: 1600px){
      min-width: 100px;
      padding: 8px 10px;
      font-size: 12px;
    }
    @media all and (max-width: 991px){
      font-size: 12px;
      padding: 8px 15px;
    }
    &.selected {
      background: $primary;
      color: $white;
    }

    &:not(:last-child) {
      margin-right: 9px;
    }
  }
}
