@import "../../styles/partials/fonts";
@import "../../styles/partials/colors";

.featured-art-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;

  .featured-art {
    .details {
      display: flex;
      justify-content: space-between;
      padding: 18px 0;

      .left {
        .name {
          font-family: "Roboto";
          font-size: 16px;
          font-weight: 700;
        }

        span {
          font-size: 14px;
          color: #9a9a9a;

          a {
            // color: $black;
            color: #9a9a9a;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .right {
        font-family: $fontSpecial;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }

  @media all and (max-width: 769px) {
    grid-gap: 18px;
  }

  @media all and (max-width: 680px) {
    grid-gap: 0;
    grid-template-columns: 1fr;
    // width: 300px;
    margin: auto;
  }
}
.web-view {
  @media screen and (max-width: 576px) {
    display: none;
  }
}
.mobile-view {
  display: none;
  @media screen and (max-width: 576px) {
    display: block;
  }

  .load-featured {
    display: flex;
    justify-content: center;
    .load-btn {
      border: none;
      border-radius: 5px;
      background-color: $primary;
      color: white;
      padding: 10px;
    }
  }
}
