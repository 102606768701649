@import "../../../../styles/partials/colors";
@import "../../../../styles/partials/fonts";

#edit-info-page {
  .commonFormSection {
    padding-top: 80px;
    @media all and (max-width: 1600px) {
      padding-top: 70px;
    }
    @media all and (max-width: 667px) {
      padding-top: 54px;
      margin-bottom: 15px;
      .customContainer {
        padding: 0;
      }
    }
    .contentCardItem {
      .title {
        display: flex;
      }
      .datePickerHide {
        display: none;
      }
      .typeTwoCol {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        .styled-input {
          padding: 0 15px;
          width: 50%;
          @media all and (max-width: 667px) {
            width: 100%;
          }
        }
      }
    }
  }

  .cover-image {
    display: flex;
    flex-direction: column;
    button {
      align-self: flex-end;
      margin-top: 9px;
      @media all and (max-width: 667px) {
        margin-top: 15px;
      }
    }
    .image-uploader-wrap {
      canvas {
        width: 100% !important;
      }
    }
  }

  .profile-picture {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .placeholder {
      width: 200px;
      height: 200px;
      background: #f2f2f2;
      border-radius: 50%;
      margin-bottom: 15px;
    }

    .pp {
      display: flex;
      flex-direction: column;

      img {
        max-width: 200px;
        border-radius: 50%;
        margin-bottom: 15px;
      }
    }
  }
  .your-styles {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    .cat {
      padding: 10px 15px;
      border: 1px solid #efeeef;
      border-radius: 5px;
      margin: 0 10px 10px 0;
      @media all and (max-width: 1600px) {
        font-size: 14px;
      }
      @media all and (max-width: 667px) {
        padding: 10px 10px;
        font-size: 12px;
        &:not(:last-of-type) {
          margin-right: 15px;
        }
      }
    }
    .activeCat {
      background-color: #212121;
      color: #efeeef;
    }
  }
}

.shippinginfo-title {
  font-size: 12px;
  color: #984b48;
  padding: 20px 0;
}

textarea {
  resize: none;
  height: 120px;
  width: 100%;
  font-size: 14px;
  padding: 10px;
  border: 1px solid #bbbbbb;
  border-radius: 5px;
  color: #7c7c7c;
}
#shippingInfo {
  color: #984b48;
  padding: 20px 0;
  font-weight: bold;
}
