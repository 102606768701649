@import "../../../styles/partials/colors";
@import "../../../styles/partials/fonts";

#userProfilePage {
  font-family: "Roboto" !important;
  .feedSection {
    // margin-top: 30px;
    .customContainer2 {
      @media all and (max-width: 667px) {
        padding-left: 0;
        padding-right: 0px;
      }
      padding: 0 20px;
      max-width: 1920px;
      margin: auto;
      width: 100%;
      @media all and (max-width: 1600px) {
        padding: 0 0px;
      }
      @media all and (max-width: 1199px) {
        padding: 0 10px;
      }
      @media all and (max-width: 991px) {
        padding: 0 15px;
      }
      &.smallWidthLayout2 {
        max-width: 90%;
        @media screen and (max-width: 600px) {
          max-width: 100%;
        }
      }
    }
    .user-bar {
      // background: #fff;
      margin-bottom: 15px;
      // box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.07058823529411765);
      display: flex;
      justify-content: center;
      padding: 15px 0;

      ul {
        display: flex;

        li {
          margin: 0 12px;

          a {
            color: $black;
            font-size: 16px;

            &.active {
              // text-decoration: underline;
              font-weight: bold;
            }
          }
        }
      }
    }

    .sectionContent {
      margin-top: 15px;
    }
    .commonFeedContainer {
      &.typeNewFeed {
        margin-top: 15px;
      }
    }
    .getFeedTitle {
      cursor: pointer;
      border-radius: 5px;
      background-image: url("../../../assets/img/pattern/get_a_studio.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      @media all and (max-width: 667px) {
        border-radius: 0;
      }
      h3 {
        font-family: $fontMain;
        font-weight: bold;
        letter-spacing: 15px;
        padding: 50px 10px 49px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        @media all and (max-width: 1600px) {
          font-size: 16px;
          padding: 35px 10px 35px;
        }
        @media all and (max-width: 667px) {
          font-size: 12px;
          letter-spacing: 12px;
          padding: 28px;
        }
      }
    }
  }
  #bio {
    .sideGroupContent {
      .sideBarItem {
        margin-bottom: 15px;
        .title {
          // margin-bottom: 20px;
          // text-align: center;
          h6 {
            color: $primary;
            font-size: 14px;
            font-weight: bold;
          }
        }
        .styles {
          position: relative;
          width: 100%;
          display: inline-block;
          margin-top: 10px;
          @media screen and (min-width: 481px) {
            width: auto;
          }
          input {
            background-color: #eceff1;
            margin: 0;
            padding: 0;
            -webkit-appearance: none;
            display: none;
          }
          .style-category {
            height: 23px;
            min-width: 60px;
            padding: 2px 5px;
            border-radius: 14px;
            border: 1px solid #bababa;
            text-align: center;
            font-size: 12px;
            cursor: pointer;
            display: inline-block;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            margin-right: 5px;
            color: #b6b6b6;
            background-color: #f2f2f2;
          }
        }
        .content {
          font-size: 14px;
          margin-top: 8px;

          //input {
          //  opacity: 0;
          //  position: absolute;
          //  pointer-events: none;
          //}
          //p {
          //  display: -webkit-box;
          //  -webkit-line-clamp: 2;
          //  -webkit-box-orient: vertical;
          //  overflow: hidden;
          //  text-align: unset;
          //  color: #000;
          //}
          //
          //label{
          //  font-weight: bold;
          //  font-size: 14px;
          //}
          //input:focus ~ label {
          //  outline: -webkit-focus-ring-color auto 5px;
          //}
          //
          //input:checked+ p {
          //  -webkit-line-clamp: unset;
          //}
          //
          //input:checked ~ label {
          //  display: none;
          //}
        }
      }
    }
  }
}
