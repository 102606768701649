/**
 * ==============================================
 * Dot Fire
 * ==============================================
 */

$leftPos: -9999px;
$x1: - $leftPos;
$y1: - 1.5*$dotSpacing;
$y2: - 1.5*$dotSpacing/4;
$y3: 1.5*$dotSpacing;

.dot-fire {
  position: relative;
  left: $leftPos;

  @include dot;

  box-shadow: $x1 $y3 0 -5px $dotColor;
  animation: dotFire 1.5s infinite linear;
  animation-delay: -.85s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;

    @include dot;
  }

  &::before {
    box-shadow: $x1 $y3 0 -5px $dotBeforeColor;
    animation: dotFire 1.5s infinite linear;
    animation-delay: -1.85s;
  }

  &::after {
    box-shadow: $x1 $y3 0 -5px $dotAfterColor;
    animation: dotFire 1.5s infinite linear;
    animation-delay: -2.85s;
  }
}

@keyframes dotFire {
  1% {
    box-shadow: $x1 $y3 0 -5px $dotAfterColor;
  }

  50% {
    box-shadow: $x1 $y2 0 2px $dotAfterColor;
  }

  100% {
    box-shadow: $x1 $y1 0 -5px $dotAfterColor;
  }
}
