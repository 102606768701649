@import "../../styles/partials/colors";

.commonAvatar {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  overflow: hidden;
  .imageContainer {
    width: 100%;
    height: 100%;
    background: $grey;
    background-image: url("https://storage.googleapis.com/tulikaabucketdev/assets/profile-dummy.png");
    background-size: cover;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
