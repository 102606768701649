@import "../../styles/partials/colors";

.searchContainer1 {
  position: relative;
  input {
    width: 200px;
    font-size: 14px;
    background: none;
    border: none;
    color: #fff;
    margin-top: 5px;
    @media all and (max-width: 1399px) {
      width: 120px;
      font-size: 12px;
    }
  }

  .results {
    position: absolute;
    background: $black;
    width: 100%;
    padding: 9px 0;
    width: 300px;
    max-height: 70vh;
    overflow-y: scroll;
    .none {
      color: #fff;
      font-size: 12px;
      text-align: center;
    }

    .search-result {
      a {
        display: flex;
        padding: 9px;
        color: $white;

        &:last-child {
          padding-bottom: 0;
        }

        img {
          max-width: 50px;
          margin-right: 9px;
          border-radius: 5px;
          background-image: "https://storage.googleapis.com/tulikaabucketdev/assets/profile-dummy.png";
        }

        .info {
          font-size: 12px;

          .name {
            font-weight: 700;
          }
        }
      }
    }
  }
}
