@import "../../styles/partials/colors";

#footer-navigation {
  background: $white;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 18px 30px;
  z-index: 800;
  box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.08);
  align-items: center;

  .nav-icon {
    a {
      position: relative;
      display: flex;
      flex-direction: column;
      // text-align: center;
      color: black;

      &.active {
        svg path {
          fill: "#aa191f";
        }
        &:after {
          content: "";
          position: absolute;
        }
      }
      img {
        // height: 32px;
        // width: 32px;
      }
    }
  }

  &.exhibition {
    display: none;
  }
}
