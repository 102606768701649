@import "../../../../styles/partials/fonts";
@import "../../../../styles/partials/colors";

.staticContentCardItem {
  border-radius: 5px;
  background: #fff;
  margin-bottom: 15px;
  .main {
    margin-bottom: -15px;
    padding: 40px;
    @media all and (max-width: 677px) {
      padding: 15px;
      margin-bottom: 0;
    }
  }

  // &:first-child {
  //   margin-top: 15px;
  // }
  // &:last-child {
  //   margin-bottom: 25px;
  // }
  // textarea {
  //   color: #333333;
  // }
  .title {
    // display: flex;
    padding: 40px;
    justify-content: space-between;
    border-bottom: 1px solid $grey-light;
    @media all and (max-width: 677px) {
      padding: 15px;
    }
    h1 {
      font-family: $fontMain;
      font-weight: bold;
      color: $primary;
      @media all and (max-width: 991px) {
        margin-bottom: 20px;
      }
    }
  }
}
