//@import url('https://fonts.googleapis.com/css?family=Alegreya:400,500|DM+Sans:400,700');
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital@0;1&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&display=swap");

@import "partials/fonts";
@import "partials/colors";
@import "partials/snippets";
@import "partials/grid";

html {
  height: 100%;
}

body {
  height: 100%;
  background: $grey-light !important;
  font-family: $fontMain;
  font-size: 16px;
  line-height: 1.3em;
  font-weight: 400;
  color: black;
  display: block;
  padding: 0px;
  margin: 0px;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $grey-mid;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $black-super-light;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $grey-light;
  }

  &.no-scroll {
    overflow: hidden;
  }
  @media all and (max-width: 1600px) {
    font-size: 14px;
  }
}

a {
  &:link,
  &:visited {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fontMain;
  font-weight: 500;
  line-height: 1.3em;
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 50px;
  @media all and (max-width: 1600px) {
    font-size: 35px;
  }
  @media all and (max-width: 1199px) {
    font-size: 30px;
  }
}
h2 {
  font-size: 30px;
  @media all and (max-width: 1600px) {
    font-size: 22px;
  }
  @media all and (max-width: 667px) {
    font-size: 20px;
  }
}
h3 {
  font-size: 24px;
  @media all and (max-width: 1600px) {
    font-size: 20px;
  }
  @media all and (max-width: 1199px) {
    font-size: 18px;
  }
}
h4 {
  font-size: 20px;
  @media all and (max-width: 1600px) {
    font-size: 16px;
  }
}
h5 {
  font-size: 16px;
  @media all and (max-width: 1600px) {
    font-size: 14px;
  }
}
h6 {
  font-size: 14px;
  @media all and (max-width: 1199px) {
    font-size: 12px;
  }
}

p {
  margin-top: 0;
  &:last-of-type {
    margin-bottom: 0;
  }
}

input,
textarea,
select,
button {
  font-family: $fontMain;
  font-size: 1em;
}
button {
  cursor: pointer;
}
* {
  &[role="button"] {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}

img {
  max-width: 100%;
}

.customContainer {
  padding: 0 80px;
  max-width: 1920px;
  margin: auto;
  width: 100%;
  @media all and (max-width: 1600px) {
    padding: 0 70px;
  }
  @media all and (max-width: 1199px) {
    padding: 0 30px;
  }
  @media all and (max-width: 991px) {
    padding: 0 15px;
  }
  &.fullWidthLayout {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }
  &.mediumWidthLayout {
    max-width: 1180px;
    @media all and (max-width: 1600px) {
      max-width: 1100px;
    }
    @media all and (max-width: 1199px) {
      max-width: 826px;
    }
    @media all and (max-width: 991px) {
      max-width: 100%;
    }
  }
  &.smallWidthLayout {
    max-width: 870px;
    @media all and (max-width: 1600px) {
      max-width: 700px;
    }
    @media all and (max-width: 1199px) {
      max-width: 560px;
    }
    @media all and (max-width: 991px) {
      max-width: 650px;
    }
  }
  &.smallestWidthLayout {
    max-width: 700px;
    @media all and (max-width: 1600px) {
      max-width: 600px;
    }
    @media all and (max-width: 1199px) {
      max-width: 560px;
    }
    @media all and (max-width: 991px) {
      max-width: 650px;
    }
  }
  &.smallerWidthLayout {
    max-width: 700px;
  }
  &.headerLayout {
    padding-left: 30px;
    padding-right: 30px;
    @media all and (max-width: 991px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &.addPaddingTopBottom {
    padding-top: 80px;
    padding-bottom: 80px;
    @media all and (max-width: 1600px) {
      padding-top: 70px;
      padding-bottom: 70px;
    }
    @media all and (max-width: 1199px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    @media all and (max-width: 667px) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  &.addPaddingTop {
    padding-top: 80px;
    @media all and (max-width: 1600px) {
      padding-top: 70px;
    }
    @media all and (max-width: 1199px) {
      padding-top: 40px;
    }
    @media all and (max-width: 667px) {
      padding-top: 30px;
    }
  }

  &.noPaddingTopBottom {
    padding-top: 0;
    padding-bottom: 0;
  }
  &.noPaddingTop {
    padding-top: 0;
  }
  &.noPaddingBottom {
    padding-bottom: 0;
  }
}

section {
  // margin-bottom: 80px;
  // @media all and (max-width: 1399px){
  //   margin-bottom: 70px;
  // }
  // @media all and (max-width: 1199px){
  //   margin-bottom: 40px;
  // }
  // @media all and (max-width: 667px){
  //   margin-bottom: 30px;
  // }
  // &.addHalfMargin{
  //   margin-bottom: 40px;
  //   @media all and (max-width: 992px){
  //     margin-bottom: 20px;
  //   }
  //   &.addHalfMarginTop{
  //     margin-top: 40px;
  //     @media all and (max-width: 992px){
  //       margin-top: 20px;
  //     }
  //   }
  // }
}

.tl-btn {
  display: inline-block;
  width: auto;
  outline: none;
  padding: 0 30px;
  background-color: #212529;
  border: 1px solid #212529;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
}

.sectionTitle {
  margin-bottom: 20px;
  h5 {
    font-weight: bold;
    text-transform: uppercase;
  }
  &.addTypeCenter {
    text-align: center;
  }
  &.addWhiteColor {
    color: #fff;
  }
  &.addBottomLine {
    position: relative;
    h5 {
      padding-bottom: 15px;
    }
    &:after {
      content: "";
      height: 1px;
      width: 50px;
      background-color: #fff;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
}
.coAddMargin {
  margin-bottom: 70px;
  &.coMarginSmall {
    margin-bottom: 35px;
    @media all and (max-width: 667px) {
      margin-bottom: 26px;
    }
  }
  @media all and (max-width: 1399px) {
    margin-bottom: 50px;
  }
  @media all and (max-width: 1199px) {
    margin-bottom: 40px;
  }
  @media all and (max-width: 667px) {
    margin-bottom: 26px;
  }
}

#root {
  height: 100%;
  position: relative;
}

#wrapper {
  min-height: 100%;
  position: relative;

  &.noFooter {
    #contentWrapper {
      padding-bottom: 0 !important;

      @media all and (max-width: 991px) {
        padding-bottom: 50px !important;
      }
    }

    .footerWrapper {
      display: none !important;
    }

    &.noMobileFooter {
      background: #000;

      #footer-navigation {
        display: none;
      }

      #contentWrapper {
        padding-bottom: 0 !important;

        @media all and (max-width: 991px) {
          padding-bottom: 0 !important;
        }
      }
    }
  }
}

.lazy-placeholder {
  /* background: $grey; */
  // background: #f5f5f5;
  min-height: 15em;
  width: 100%;
  position: relative;
  max-height: 30em;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.content-scroller {
  ._arrow_prev,
  ._arrow_next {
    @media all and (max-width: 769px) {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      z-index: 500;
    }
  }
}

.global-fade-enter {
  opacity: 0;
}
.global-fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.global-fade-exit {
  opacity: 1;
}
.global-fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
.MuiSnackbarContent-root {
  flex-wrap: unset !important;
}
.MuiSnackbarContent-message {
  padding: 5px 0 !important;
}
#message-id {
  @media screen and (max-width: 481px) {
    font-size: 12px !important;
  }
}

.margin-auto {
  margin: auto;
}
