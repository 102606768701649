@import '../../styles/partials/snippets';
@import '../../styles/partials/colors';

.commonLikeButton{
  background-color: transparent;
  border: 1px solid $grey-mid;
  padding: 5px 15px 3px;
  border-radius: 20px;
  @extend .ex-flex;
  align-items: center;
  @media all and (max-width: 1600px){
    padding: 7px 13px 4px;
  }
  span{
    &:nth-of-type(1){
      margin-right: 5px;
      svg{
        font-size: 18px;
        height: 1em;
        width: 1em;
        @media all and (max-width: 1600px){
          font-size: 14px;
        }
      }
    }
    &:nth-of-type(2){
      font-size: 12px;
      margin-top: -4px;
      @media all and (max-width: 1600px){
        font-size: 10px;
        margin-top: -2px;
      }
    }
  }

  &.isLiked {
    background: $primary;
    color: $white;
  }
}
