/**
 * ==============================================
 * Dot Collision
 * ==============================================
 */

.dot-collision {
  position: relative;

  @include dot;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: - $dotWidth;

    @include dot($bgColor: $dotBeforeColor);

    animation: dotCollisionBefore 2s infinite ease-in;
  }

  &::after {
    left: $dotWidth;

    @include dot($bgColor: $dotAfterColor);

    animation: dotCollisionAfter 2s infinite ease-in;
    animation-delay: 1s;
  }
}

@keyframes dotCollisionBefore {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-$dotSpacing);
  }
}

@keyframes dotCollisionAfter {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX($dotSpacing);
  }
}
