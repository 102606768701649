/**
 * ==============================================
 * Experiment-Gooey Effect
 * Dot Overtaking
 * ==============================================
 */

$dotColorHSL: hsl(0, 100%, 0%);

.dot-overtaking {
  position: relative;

  @include dot($width: 12px, $height: 12px, $radius: 6px, $bgColor: transparent, $color: $dotColorHSL);

  margin: -1px 0;
  box-shadow: 0 -20px 0 0;
  filter: blur(2px);
  animation: dotOvertaking 2s infinite cubic-bezier(.2, .6, .8, .2);

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;

    @include dot($width: 12px, $height: 12px, $radius: 6px, $bgColor: transparent, $color: $dotColorHSL);

    box-shadow: 0 -20px 0 0;
    filter: blur(2px);
  }

  &::before {
    animation: dotOvertaking 2s infinite cubic-bezier(.2, .6, .8, .2);
    animation-delay: .3s;
  }

  &::after {
    animation: dotOvertaking 1.5s infinite cubic-bezier(.2, .6, .8, .2);
    animation-delay: .6s;
  }
}

@keyframes dotOvertaking {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}
