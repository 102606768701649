@import "../../../../styles/partials/colors";

#page-upload-art {
  .commonFormSection {
    padding-top: 80px;
    @media all and (max-width: 677px) {
      padding-top: 54px;
      margin-bottom: 15px;
      .customContainer {
        padding: 0;
      }
    }
    .contentCardItem {
      .tagsWrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: -12px;
        .bg-secondary {
          background-color: black !important;
          text-transform: uppercase;
          padding: 5px 8px;
          border: 3px solid white;
          border-radius: 6px;
        }
      }
      .typeThreeCol {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        @media all and (max-width: 667px) {
          margin: 0 -10px;
        }

        .styled-input {
          padding: 0 15px;
          width: 33.33%;
          @media all and (max-width: 667px) {
            width: 50%;
            padding: 0 10px;
          }
        }
      }
    }

    .fancy-nav {
      span {
        font-size: 14px;
      }
    }
  }

  .category-list {
    padding-top: 15px;
    font-size: 14px;

    .category-select {
      padding: 8px 15px;
      background: #fff;
      color: $black;
      border: 1px solid $grey-mid;
      border-radius: 17px;
      margin: 0 15px 15px 0;
      outline: none;
      @media all and (max-width: 667px) {
        font-size: 12px;
        margin: 0 10px 10px 0;
      }

      &.active {
        background: #dedede;
        color: $black;
      }
    }
  }

  .delete-art-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    small {
      margin-bottom: 12px;
      font-size: 12px;
      font-style: italic;
    }
  }
}
