@import "../../styles/partials/colors";
@import "../../styles/partials/fonts";
@import "../../styles/partials/snippets";

.art-showcase-container {
  display: flex;

  .showcase-column {
    flex: 1;
  }
}

.artItem {
  .inner {
    position: relative;
    .imageContainer {
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 3px 3px 6px #0000000d;
      // box-shadow: 0 3px 4px rgba(0, 0, 0, 0.19);
      height: 100%;
      vertical-align: top;
      @media all and (max-width: 991px) {
        border-radius: 6px;
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.07);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        max-width: none;
        vertical-align: top;
      }
    }

    .like-art-button {
      position: absolute;
      bottom: 5px;
      height: 35px;
      width: 35px;
      right: 10px;
      background: rgba(0, 0, 0, 0.2);
      border: none;
      // @media screen and (min-width: 1600px) {
      //   bottom: 80px;
      //   right: 15px;
      // }
      svg {
        padding: 4px;
      }
      &.liked {
        background: $primary;
        border-color: $primary;
      }
      &.loading {
        opacity: 0.5;
        transition: all 0.3s ease;
        pointer-events: none;
      }

      &:hover {
        border-width: 0;
        transition: all 0.3s ease;
      }
    }

    .edit-art {
      background: $white;
      border-radius: 21px;
      border: none;
      font-size: 12px;
      padding: 8px 12px;
      position: absolute;
      right: 9px;
      z-index: 10;
      top: 9px;
      display: flex;
      align-items: center;
      height: 30px;
      span {
        margin-left: 6px;
      }
    }

    .textContainer {
      @extend .ex-flex;
      @extend .ex-flex-wrap;
      justify-content: space-between;
      margin-top: 10px;
      padding: 0;
      @media all and (max-width: 667px) {
        flex-wrap: nowrap;
      }
      .textItem {
        h5 {
          // font-family: $fontSpecial;
          font-weight: bold;
          margin-bottom: 3px;
        }
        span {
          font-family: $fontMain;
          font-size: 14px;

          a {
            // color: $black;
            color: #a8a8a8;
            &:hover {
              // text-decoration: underline;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
#artworkAuthorName {
  font-size: 12px;
  color: rgb(179, 179, 179);
}
