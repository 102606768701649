/**
 * ==============================================
 * Experiment-Emoji
 * Dot Rolling
 * ==============================================
 */

$d: 20px;

.dot-rolling {
  position: relative;
  height: $dotHeight;
  font-size: 10px;

  &::before {
    content: '⚽';
    display: inline-block;
    position: relative;
    transform: translateX(-25px);
    animation: dotRolling 3s infinite;
  }
}

@keyframes dotRolling {
  0% {
    content: '⚽';
    transform: translateX(-25px) rotateZ(0deg);
  }

  16.667% {
    content: '⚽';
    transform: translateX(25px) rotateZ(720deg);
  }

  33.333% {
    content: '⚽';
    transform: translateX(-25px) rotateZ(0deg);
  }

  34.333% {
    content: '🏀';
    transform: translateX(-25px) rotateZ(0deg);
  }

  50% {
    content: '🏀';
    transform: translateX(25px) rotateZ(720deg);
  }

  66.667% {
    content: '🏀';
    transform: translateX(-25px) rotateZ(0deg);
  }

  67.667% {
    content: '🏐';
    transform: translateX(-25px) rotateZ(0deg);
  }

  83.333% {
    content: '🏐';
    transform: translateX(25px) rotateZ(720deg);
  }

  100% {
    content: '🏐';
    transform: translateX(-25px) rotateZ(0deg);
  }
}
