@import "../../../styles/partials/colors";
@import "../../../styles/partials/fonts";
@import "../../../styles/partials/snippets";

#artistPage {
  .action {
    button {
      border-radius: 20px;
      height: 30px;
      width: 100px;
    }
    .cart-btn {
      border: 1px solid #dedede;
      background: #fff;
    }
    .saved-btn {
      border: 1px solid #212121;
      background: #fff;
    }
  }

  .artist-bar {
    //background: #fff;
    margin-bottom: 15px;
    //box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.07058823529411765);
    display: flex;
    justify-content: center;
    padding: 15px 0;

    ul {
      display: flex;

      li {
        margin: 0 6px;
        a {
          color: $black;
          font-size: 16px;
          &.active {
            //text-decoration: underline;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.likedartworks {
  width: 50%;
  margin: 0 auto;
  margin-top: -200px;
}

.imageStyle {
  border-radius: 5px;
}
.report-profile {
  position: fixed;
  bottom: 0px;
  background-color: #fff;
  // width: 320px;
  // top: -20px;
  padding: 5px;
  font-size: 12px;
  color: gray;
  cursor: pointer;
  @media all and (max-width: 1600px) {
    width: 250px;
  }
  @media all and (max-width: 991px) {
    width: fit-content;
    position: absolute;
    bottom: 5px;
    background: none;
  }
  .pro-report {
    padding-right: 5px;
    &:after {
      content: " \007C";
    }
  }
}
#sidebar .inner {
  @media screen and (min-width: 992px) {
    min-height: 100vh;
  }
}
