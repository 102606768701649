@import '../../styles/partials/colors';
@import '../../styles/partials/snippets';

.bannerNavItem{
  width: 25%;
  padding: 0 6px;
  @extend .ex-flex;
  .imageContainer{
    height: 100px;
    width: 100px;
    background-color: $grey;
    border-radius: 6px;
    overflow: hidden;
    @media all and (max-width: 1600px) {
      height: 80px;
      width: 80px;
    }
    @media all and (max-width: 1199px) {
      height: 70px;
      width: 70px;
    }
    img{
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
  }
  .textContainer{
    width: calc(100% - 100px);
    padding-left: 15px;
    padding-top: 10px;
    @media all and (max-width: 1600px) {
      width: calc(100% - 80px);
    }
    @media all and (max-width: 1199px) {
      width: calc(100% - 70px);
    }
    p,h4{
      line-height: 1.3em;
      font-size: 14px;
      @media all and (max-width: 1199px) {
        font-size: 12px;
      }
    }
    p{
      position: relative;
      padding-bottom: 10px;
      margin-bottom: 11px;

      a {
        color: $black;

        &:hover {
          text-decoration: underline;
        }
      }
      @media all and (max-width: 1199px) {
        padding-bottom: 8px;
        margin-bottom: 10px;
      }
      &::after{
        content: '';
        height: 1px;
        width: 20px;
        background-color: $black;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}
