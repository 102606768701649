@import "../../../styles/partials/colors";
@import "../../../styles/partials/fonts";
@import "../../../styles/partials/snippets";

#feedPage {
  .articleBoxContainer {
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 16px;
  }

  .sideFilterButton {
    display: none;
    position: absolute;
    z-index: 999;
    background: #fff;
    border-radius: 0 30px 30px 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
    justify-content: center;
    align-items: center;
    bottom: 120px;
    right: -51px;
    border-left: none;
    width: 52px;
    height: 49px;

    @media all and (max-width: 667px) {
      display: flex;
    }
  }
  .feedWrapper {
    @extend .ex-flex;
    .feedBlock {
      &.feedFilters {
        width: 320px;
        background-color: #fff;
        @media all and (max-width: 1600px) {
          width: 300px;
        }
        @media all and (max-width: 1399px) {
          width: 280px;
        }
        @media all and (max-width: 1199px) {
          width: 260px;
        }
        @media all and (max-width: 667px) {
          position: absolute;
          box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
          left: -263px;
          position: fixed;
          z-index: 500;
          transition: all 0.3s ease;

          .inner {
            position: static;
            overflow: auto;
            height: calc(100vh - 44px);
            padding-bottom: 60px !important;
          }

          &.visible {
            left: 0;
            transition: all 0.3s ease;
          }
        }
        .inner {
          position: sticky;
          padding: 40px;
          top: 46px;
          min-height: 100vh;
          @media all and (max-width: 1199px) {
            padding: 30px;
          }
          .filterItem {
            &:not(:last-of-type) {
              margin-bottom: 30px;
            }
            .title {
              padding-bottom: 12px;
              margin-bottom: 15px;
              border-bottom: 1px solid $grey-mid;
              h4 {
                font-weight: bold;
                color: $primary;
              }
              h6 {
                font-family: $fontMain;
              }
            }
            .content {
              ul {
                li {
                  &:not(:last-of-type) {
                    margin-bottom: 4px;
                  }
                  button {
                    position: relative;
                    width: 100%;
                    font-size: 16px;
                    color: $grey;
                    padding: 10px 0px;
                    background: transparent;
                    border: 0;
                    text-align: left;
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                    cursor: pointer;
                    @extend .ex-flex;
                    align-items: center;
                    @media all and (max-width: 1600px) {
                      font-size: 14px;
                    }
                    @media all and (max-width: 1399px) {
                      font-size: 12px;
                    }
                    svg {
                      position: absolute;
                      right: 5px;
                      @extend .ex-vertical;
                      opacity: 0;
                    }

                    &:after {
                      content: "";
                      height: 100%;
                      width: 38px;
                      background-color: $grey-light;
                      position: absolute;
                      left: -38px;
                      top: 0;
                      opacity: 0;
                    }
                    &.active {
                      background-color: $grey-light;
                      color: black;
                      svg {
                        opacity: 1;
                      }
                      &:after {
                        opacity: 1;
                      }
                    }
                  }
                }
              }
            }
            &.filterStudio {
              .content {
                ul li {
                  &:not(:last-of-type) {
                    margin-bottom: 15px;
                    @media all and (max-width: 1199px) {
                      margin-bottom: 10px;
                    }
                  }
                  button {
                    padding: 6px 0;
                    // padding: 6px 8px;
                    span {
                      display: inline-block;
                      &:nth-of-type(1) {
                        width: 30px;
                        @media all and (max-width: 1199px) {
                          width: 25px;
                        }
                      }
                      &:nth-of-type(2) {
                        width: calc(100% - 30px);
                        padding-left: 10px;
                        color: black;
                        font-size: 14px;
                        @media all and (max-width: 1199px) {
                          width: calc(100% - 25px);
                          padding-left: 8px;
                          font-size: 12px;
                        }
                      }
                      .commonAvatar {
                        height: 30px;
                        width: 30px;
                        @media all and (max-width: 1199px) {
                          height: 25px;
                          width: 25px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.feedContent {
        -webkit-flex: auto;
        flex: auto;
      }
    }
  }
}
