#likedartwork-page {
  .commonPageHead {
    display: none;
    @media screen and (max-width: 991px) {
      display: block;
    }
  }
  .contentCardItem {
    padding: 20px;
  }
  .artworks {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    @media screen and (max-width: 991px) {
      width: 80%;
    }
    @media screen and (max-width: 688px) {
      width: 100%;
    }
  }
}

// @media screen and (max-width: 600px) {
//   #likedartwork-page {
//     .artworks {
//       width: 100%;
//     }
//   }
// }
