@import "../../styles//partials/colors";

.image-container {
  overflow: hidden;
  /* background: $grey; */
  img {
    height: 100%;
    width: 100%;

    &.cover {
      object-fit: cover;
    }

    &.contain {
      object-fit: contain;
    }

    &.fill {
      object-fit: fill;
    }

    &.scale-down {
      object-fit: scale-down;
    }

    &.none {
      object-fit: none;
    }
  }
}
