/**
 * ==============================================
 * Dot Elastic
 * ==============================================
 */

.dot-elastic {
  position: relative;

  @include dot;

  animation: dotElastic 1s infinite linear;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: - $dotSpacing;

    @include dot($bgColor: $dotBeforeColor);

    animation: dotElasticBefore 1s infinite linear;
  }

  &::after {
    left: $dotSpacing;

    @include dot($bgColor: $dotAfterColor);

    animation: dotElasticAfter 1s infinite linear;
  }
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1.5);
  }

  50% {
    transform: scale(1, .67);
  }

  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 1.5);
  }

  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, .67);
  }

  75% {
    transform: scale(1, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}
