@import "../../styles/partials/colors";

.dropdown {
  position: relative;

  .dropdown-menu {
    position: absolute;
    z-index: 5;
    top: 42px;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    
    ul {
      margin: 0;
      padding: 3px 0;
      background: $white;
      border-radius: 5px;
      border: 1px solid $grey-mid;
      min-width: 145px;

      li {
        font-size: 12px;
        font-weight: 700;
        padding: 9px 15px;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  &.active {

    .dropdown-menu {
      opacity: 1;
      visibility: visible;
      transition: all .3s ease;
    }
  }
}