@import "../../styles/partials/colors";

.like-art-button {
  background: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #e8e8e8;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 25px;
  &.liked {
    background: $primary;
    border-color: $primary;
  }

  &.loading {
    opacity: 0.5;
    transition: all 0.3s ease;
    pointer-events: none;
  }

  &:hover {
    border-width: 0;
    transition: all 0.3s ease;
  }
}
