@import "../../../styles/partials/colors";
@import "../../../styles/partials/fonts";

#search-page {
  padding: 30px 0;

  .search-results {
    ._title {
      margin-bottom: 30px;
      font-size: 22px;
    }

    .search-result {
      margin-bottom: 15px;
      background: #fff;
      padding: 9px;

      a {
        display: flex;
        color: $black;
        .commonAvatar {
          height: 60px;
          width: 60px;
          margin-right: 10px;
        }
        img {
          background-image: "https://storage.googleapis.com/tulikaabucketdev/assets/profile-dummy.png";
          margin-right: 15px;
          border-radius: 9px;
          max-width: 150px;
        }

        .info {
          padding-top: 5px;
          .artist-name {
            font-size: 20px;
            margin-top: 12px;
            font-family: $fontSpecial;
            font-weight: bold;
          }

          .name {
            font-size: 20px;
            margin-bottom: 10px;
            font-family: $fontSpecial;
            font-weight: bold;
          }
          .author {
            font-weight: bold;
          }
        }
      }
    }
  }
}
