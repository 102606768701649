@import "../../styles/partials/colors";

.styled-input {
  margin-bottom: 15px;
  label {
    width: 100%;
    display: flex;
    flex-direction: column;

    small {
      font-size: 10px;
    }

    span {
      font-size: 12px;
      @media all and (max-width: 677px) {
        margin-bottom: 10px;
      }
    }

    input,
    textarea {
      font-size: 14px;
      padding: 10px 5px;
      border: none;
      border-bottom: 1px solid $grey;
      color: #7c7c7c;
      &:hover,
      &:focus,
      &:active {
        color: $black;
      }
      @media all and (max-width: 677px) {
        font-size: 12px;
        border: 1px solid $grey-mid;
        border-radius: 6px;
        padding: 10px;
      }
    }
    textarea {
      resize: none;
      height: 120px;
    }

    input,
    textarea {
      &::-webkit-input-placeholder {
        /* Chrome */
        color: $grey;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $grey;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $grey;
      }
      &:-moz-placeholder {
        /* Firefox 4 - 18 */
        color: $grey;
      }
    }
  }
}
