.studios-to-follow .react-tabs__tab--selected {
  color: #000000 !important;
  border: none;
}
.studios-to-follow
  .exhibitions_sliderWrap__mw-kg
  .exhibitions_reactTabsTabList__KaFG1 {
  margin: 0;
}

.studios-to-follow .react-tabs__tab-list {
  border-bottom: none;
  // margin-top: -7px;
  margin-left: 20px;
  font-size: 16px;
  @media screen and (max-width: 395px) {
    margin-left: 0;
  }
}
.studios-to-follow {
  .react-tabs {
    .carousel-tabss {
      display: flex;
      @media screen and (max-width: 395px) {
        display: block;
      }
    }
  }
}

.studios-to-follow .react-tabs__tab {
  color: #9a9a9a;
  padding: 6px 12px 12px 0;
}
.studios-to-follow .react-tabs__tab:focus {
  box-shadow: none;
  border-color: none;
  outline: none;
}
