@import "../../../styles/partials/colors";

#galleryPage {
  .gallerySection {
    padding: 30px 0;
  }
  .commonPageHead {
    background-image: url("../../../assets//img/pattern/Gallery.jpg");
  }
  .commonArtShowcaseContainer .artItem {
    width: 20%;
  }

  .subNavItem ul li:first-of-type {
    padding-right: 30px;
  }
  // .commonSubNavSection ul li:not(:last-of-type) {
  //   margin-right: 20px;
  // }
  .commonSubNavSection {
    @media screen and (max-width: 1060px) {
      display: none;
    }
  }
  .commonSubNavSection ul li {
    margin-right: 10px;
  }

  .mobileSelectSection {
    display: none;
    @media screen and (max-width: 1060px) {
      display: block;
      z-index: 10;
      position: sticky;
      top: 40px;
    }
    .filterContainer {
      background-color: #fff;
      display: flex;
      align-items: center;
      .filterItem {
        position: relative;
        width: 50%;
        background-color: #fff;
        padding: 10px 10px;
        &:nth-of-type(1) {
          &:after {
            content: "";
            height: 25px;
            width: 1px;
            background-color: $grey-mid;

            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        svg {
          color: $grey;
          position: absolute;
          right: 7px;
          font-size: 18px;
          top: 50%;
          transform: translateY(-50%);
        }
        ul {
          li {
            font-size: 12px;
            display: block;
            text-align: center;
            color: $grey;
            &:nth-of-type(2) {
              font-size: 14px;
              color: $primary;
            }
          }
        }
      }
    }
  }

  #sort-options {
    li {
      &:first-of-type {
        padding-right: 0;
      }
      // &:last-child {
      //   margin-right: 20px;
      // }
    }
  }

  #price-options {
    li {
      padding: 30px 15px;
      background: $black;
    }
  }
}
