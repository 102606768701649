//cart page css
.cart-page {
  font-family: "Roboto";
  .cart-header {
    background-color: #fff;
    height: 70px;
    padding: 20px 32px;
    display: flex;
    margin-bottom: 20px;
    @media screen and (max-width: 950px) {
      padding: 20px 5px;
    }
    .left {
      font-size: 25px;
      font-weight: bold;
      //min-width: 70%;
      padding: 5px;
    }
    .right {
      margin-right: 0;
      margin-left: auto;
      //min-width: 30%;
      text-align: end;
      .total-price {
        margin-right: 25px;
        font-size: 16px;
        font-weight: bold;
        @media screen and (max-width: 480px) {
          display: none;
        }
      }
      .proceed-btn {
        font-family: "Roboto";
        border-radius: 20px;
        background-color: #984b48;
        border: none;
        color: #fff;
        padding: 8px 20px;
        font-size: 14px;
      }
    }
  }
  .responsive-total-price {
    display: none;
    @media screen and (max-width: 480px) {
      display: block;
      font-weight: bold;
      text-align: right;
      margin-right: 10px;
      font-size: 16px;
    }
  }
  .items {
    width: 50%;
    margin: auto;
    padding: 20px 10px 10px 10px;
    @media screen and (max-width: 950px) {
      width: 100%;
      margin-left: unset;
      margin-right: unset;
    }
    table {
      background-color: #ffffff;
      padding: 10px;
      border-radius: 5px;
      margin-top: 10px;
      width: 80%;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
      tr {
        padding: 0px 10px;
      }
      th {
        border-bottom: 2px solid #f3f3f3;
        padding: 12px 20px;
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        color: #9a9a9a;
        @media screen and (max-width: 480px) {
          display: none;
        }
      }
      td {
        padding-right: 20px;
        padding-left: 20px;
        @media screen and (max-width: 992px) {
          padding-right: 5px;
          padding-left: 5px;
        }
      }

      .art-desc {
        display: flex;
        width: 300px;
        margin-right: 30px;
        @media screen and (max-width: 991px) {
          width: unset;
          margin-right: unset;
        }
        img {
          max-height: 100px;
          max-width: 100%;
          margin: 10px 0;
          border-radius: 5px;
          min-width: 40px;
          min-height: 20px;
        }
        .desc {
          margin-top: 30px;
          margin-left: 15px;
          .art-name {
            font-family: "Lora" !important;
            font-size: 16px;
            font-weight: bold;
          }
          .artist {
            font-size: 14px;
            color: #9a9a9a;
            font-style: italic;
          }
          .responsive-price {
            display: none;
            @media screen and (max-width: 480px) {
              display: block;
            }
          }
        }
      }
      .art-price {
        min-width: 120px;
        padding-right: 30px;
        @media screen and (max-width: 480px) {
          display: none;
        }
      }
      .dlt-icon {
        width: 18px;
        height: 18px;
      }
    }
  }
  .no-items {
    text-align: center;
    margin-top: 20px;
  }
}
