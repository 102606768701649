.static-page {
  width: 50%;
  margin: auto;
  line-height: 1.7em;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
  h3 {
    font-weight: bold;
  }
}
.terms-conditions ul li {
  list-style-type: disc;
}
