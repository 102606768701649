@import "../../styles/partials/colors";

.art-from-followed {
  .popularItem {
    position: relative;
    .imageContainer {
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      // box-shadow: 0 3px 4px rgba(0, 0, 0, 0.19);
      vertical-align: top;
      @media all and (max-width: 991px) {
        // box-shadow: 0 3px 4px rgba(0, 0, 0, 0.07);
      }
      img {
        height: 300px;
        // max-height: 300px;
        object-fit: cover;
        object-position: center;
        max-width: none;
        vertical-align: top;
        min-width: 150px;
        max-width: 600px;
      }
      .lazy-placeholder-2 {
        background: #f5f5f5;
        position: relative;
        height: 300px;
        margin-right: 8px;
        border-radius: 5px;
        // width: 100%;
      }
    }
    .like-art-button {
      position: absolute;
      bottom: 75px;
      height: 40px;
      width: 40px;
      right: 10px;
      background: rgba(255, 250, 250, 0.2);
      border: none;
      @media screen and (min-width: 1600px) {
        bottom: 80px;
        right: 15px;
      }
      svg {
        padding: 4px;
      }
      &.liked {
        background: $primary;
        border-color: $primary;
      }
      &.loading {
        opacity: 0.5;
        transition: all 0.3s ease;
        pointer-events: none;
      }

      &:hover {
        border-width: 0;
        transition: all 0.3s ease;
      }
    }
  }
}

.artTextContainer {
  justify-content: space-between;
  margin-top: 10px;
  padding: 0;
}

.artTextContainer .artTextItem h5 {
  font-family: "Lora";
  font-weight: bold;
  margin-bottom: 3px;
}

.artTextContainer .artTextItem span {
  font-size: 14px;
  color: #9a9a9a;
}
.artTextContainer .artTextItem span a {
  color: #9a9a9a;
}
.artTextContainer .artTextItem span a:hover {
  text-decoration: underline;
}
