@import "../../../../styles/partials/colors";
@import "../../../../styles/partials/fonts";
.privacy-setting {
  .sectionContent {
    background-color: #fff;
    margin: 20px 0px;
  }
  .commonUserContainer {
    padding: 20px;
    h5 {
      font-weight: 900;
    }
    p {
      margin: 10px 0px;
    }
    h6 {
      font-weight: 900;
    }
    table {
      width: 100%;
      margin: 20px 0px;
      td {
        padding-bottom: 10px;
      }
      .image {
        display: flex;
        flex-direction: row;
        .pp-img {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          background: url("https://storage.googleapis.com/tulikaabucketdev/assets/profile-dummy.png");
          margin-right: 15px;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        .name {
          font-weight: 900;
        }
        .category,
        .followers {
          color: $black-light;
        }
      }
      .unblock-btn {
        display: flex;
        justify-content: end;
        text-align: center;
        button {
          color: $primary;
          border: none;
          outline: none;
          background: none;
        }
      }
    }
  }
}
