//checkout page css
.for-web {
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.checkout-page {
  font-family: "Roboto";
  .checkout-header {
    background-color: #fff;
    height: 70px;
    padding: 20px 32px;
    display: flex;
    .left {
      font-size: 25px;
      font-weight: bold;
      padding: 5px;
      min-width: 25%;
    }
    .center {
      min-width: 50%;
      .payment-title {
        font-size: 16px;
        font-weight: bold;
        color: #984b48;
        padding-bottom: 100px;
      }
    }
    .right {
      min-width: 25%;
      text-align: end;
      .total-price {
        margin-right: 25px;
        font-size: 16px;
        font-weight: bold;
      }
      .proceed-btn {
        font-family: "Roboto";
        border-radius: 20px;
        background-color: #000;
        border: none;
        color: #fff;
        padding: 8px 20px;
        font-size: 14px;
      }
    }
  }
  .items {
    padding-top: 20px;
    margin: auto;
    padding: 10px;
    display: flex;
    width: 100%;
    @media screen and (max-width: 1361px) {
      background-color: #fff;
      display: block;
    }
    @media screen and (max-width: 1100px) {
      margin: 0;
    }
    table {
      background-color: #ffffff;
      padding: 10px;
      border-radius: 5px;
      margin-top: 10px;
      @media screen and (max-width: 1361px) {
        width: 100%;
        margin: auto;
      }
      tr {
        padding: 0px 10px;
      }
      th {
        border-bottom: 2px solid #f3f3f3;
        padding: 12px 20px 10px 20px;
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        color: #9a9a9a;
      }
      th:last-child {
        @media screen and (max-width: 1361px) {
          text-align: end;
        }
      }
      td {
        padding-right: 15px;
        padding-left: 15px;
      }

      .art-desc {
        display: flex;
        //width: 240px;
        // margin-right: 30px;
        @media screen and (max-width: 1173px) {
          width: unset;
          margin-right: unset;
        }
        @media screen and (max-width: 700px) {
          width: auto;
        }

        img {
          height: 100px;
          width: 100px;
          margin: 10px 0;
          border-radius: 5px;
        }
        .desc {
          margin-top: 30px;
          margin-left: 15px;
          .art-name {
            font-family: "Lora" !important;
            font-size: 16px;
            font-weight: bold;
          }
          .artist {
            font-size: 14px;
            color: #9a9a9a;
            font-style: italic;
          }
        }
      }
      .art-price {
        // padding-right: 50px;
        min-width: 120px;
        @media screen and (max-width: 1361px) {
          padding-right: 15px;
          text-align: end;
        }
      }
    }
  }
  .total-amount {
    background-color: #fff;
    margin-left: 10px;
    margin-top: 10px;
    height: fit-content;

    @media screen and (max-width: 1361px) {
      margin-left: 0px;
      border-top: 1px dashed #9a9a9a;
      width: 100% !important;
    }
    @media screen and (min-width: 1362px) {
      padding: 15px;
    }
    table {
      width: 100%;
      border-radius: 5px;
      background-color: #ffffff;
      padding: 10px;
      margin-top: 10px;
      @media screen and (max-width: 1361px) {
        width: 100%;
        margin: auto;
      }
      tr {
        padding: 0px 10px;
      }
      .tax {
        padding-bottom: 10px;
        border-bottom: 1px dashed #9a9a9a;
      }
      td {
        line-height: 1.5;
        padding-top: 5px;

        padding-left: unset;
        padding-right: unset;
      }
      td:first-child {
        font-weight: bold;
      }
      td:last-child {
        text-align: right;
        padding-left: 25px;
      }
      // .grand-total {
      //   padding: 10px;
      // }
    }
  }
  .shipping-info {
    .styled-input {
      margin-bottom: 15px;
      label {
        font-weight: bold;
        span {
          font-size: 14px;
        }
      }
    }
    .contentCardItem {
      .title {
        margin-bottom: 15px;
      }
    }
  }
  .no-items {
    text-align: center;
    margin-top: 20px;
  }
  .review-section {
    margin-top: 20px;
    background: #ffff;
    .checkmark {
      width: 100%;
      margin: auto;
      color: #21a737;
      font-size: 80px;
      padding-top: 0;
      margin-top: 10px;
    }
    .review-txt {
      font-weight: bold;
      text-align: center;
    }
    h4 {
      text-align: center;
      font-weight: bold;
      margin-left: 10px;
    }
    .contentCardItem {
      background: none;
    }
    .contentCardItem .title {
      margin-bottom: unset;
    }
    .items {
      display: block;
      .review-items {
        border-top: 1px solid #ebebea;
        width: 100% !important;
        .item-price {
          text-align: right;
        }
      }
      .total-amount {
        border-top: 1px dashed #9a9a9a;
        width: 100% !important;
      }
    }
  }
}

.checkout-tab-main {
  display: flex;
  padding-bottom: 50px;
  //width: 50%;
  width: fit-content;
  margin: auto;
  padding-top: 10px;

  @media screen and (max-width: 992px) {
    padding-top: 20px;
    padding-bottom: unset;
  }
  @media screen and (max-width: 700px) {
    width: 60%;
  }
  @media screen and (max-width: 580px) {
    width: 65%;
    // padding-bottom: 20px;
    font-size: 12px;
  }
  @media screen and (max-width: 510px) {
    width: 75%;
    font-size: 10px;
  }
  @media screen and (max-width: 420px) {
    width: 80%;
  }
  @media screen and (max-width: 390px) {
    width: 82%;
  }
  @media screen and (max-width: 380px) {
    width: 80%;
  }
  @media screen and (max-width: 330px) {
    width: 90%;
  }
}
.react-tabs__tab--selected {
  font-weight: bold;
  background: none;
}
.checkout-tabs {
  text-transform: initial;
  font-size: 16px;
  cursor: pointer;
  min-width: max-content;
  max-width: fit-content;
  padding: 0 5px;
  &::after {
    content: ">";
    margin-left: 5px;
    margin-right: 5px;
  }
  @media screen and (max-width: 380px) {
    font-size: 14px;
    // min-width: 80px;
  }
}
.checkout-tabs:last-child::after {
  display: none;
}

.for-responsive {
  display: none;
  @media screen and (max-width: 992px) {
    display: block;
  }
  .checkout-header {
    .left {
      font-size: 25px;
      font-weight: bold;
      padding: 5px;
      min-width: 50%;
    }
    .right {
      min-width: 50%;
    }
  }
  .center {
    width: 50%;
    margin: auto;
    @media screen and (max-width: 1000px) {
      width: unset;
      margin: unset;
    }
    .items {
      width: unset;
      margin-top: 20px;
      .items-table {
        width: 120%;
        @media screen and (max-width: 1214px) {
          width: 100%;
        }
        .art-desc {
          width: unset;
        }
      }
      .total-amount {
        width: 100%;
        @media screen and (max-width: 580px) {
          width: 100%;
        }
      }
    }
  }
}
