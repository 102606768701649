@import "../../styles/partials/colors";
.artist-gallery-inline {
  .artist-gallery-inline-image {
    img {
      height: 200px;
      max-width: 400px;
      // max-height: 300px;
      width: auto;
    }
    .lazy-placeholder-2 {
      background: #f5f5f5;
      position: relative;
      height: 200px;
      margin-right: 8px;
      border-radius: 5px;
      // width: 100%;
    }
  }
}
