@import "../../styles/partials/colors";
@import "../../styles/partials/mixins";
@import "../../styles/partials/snippets";

.pill {
  padding: 30px 40px;
  border-right: 1px solid $grey-light;
  @media all and (max-width: 667px) {
    border-right: 0;
  }
  &:first-child {
    border-left: 1px solid $grey-light;
    @media all and (max-width: 667px) {
      border-left: 0;
    }
  }
  @media all and (max-width: 1399px) {
    padding: 25px 30px;
  }
  @media all and (max-width: 667px) {
    padding: 10px;
    font-size: 10px;
  }
}

.contentScroller {
  background: $white;
  @include borderRadius();
  @include boxShadow();
  @media all and (max-width: 667px) {
    border-radius: 0;
  }
}

.top {
  display: flex;
  border-bottom: 1px solid $grey-light;
  align-items: center;
  position: relative;
}

.title {
  padding: 30px 40px;
  font-weight: 700;
  text-transform: uppercase;
  @media all and (max-width: 1399px) {
    padding: 25px 28px;
  }
  @media all and (max-width: 667px) {
    padding: 14px 10px;
    font-size: 12px;
    font-weight: bold;
  }
}

.tabs {
  background: #fff;
}

.tab {
  color: $grey;
  composes: pill;
  transition: all 0.15s ease;
  &:global(.active) {
    color: $primary;
    transition: all 0.15s ease;
  }
  @media all and (max-width: 991px) {
    &:not(:first-of-type) {
      display: none;
    }
  }
}

.arrows {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.arrow {
  width: 84px;
  height: 84px;
  @media all and (max-width: 1399px) {
    width: 70px;
    height: 67px;
  }
  @media all and (max-width: 667px) {
    height: 50px;
    width: 50px;
  }
  &:first-child {
    border-right: 1px solid $grey-light;
  }
}

.main {
  position: relative;
  padding: 40px;
  display: flex;
  overflow: auto;
  min-height: 256px;
  @media all and (max-width: 991px) {
    padding: 25px;
    min-height: 200px;
  }
  @media all and (max-width: 991px) {
    padding: 10px;
    min-height: 100px;
  }
}

.scrollerWrap {
  width: 100%;
  overflow: hidden;
}

.track {
  display: flex;
  width: max-content;
  margin: -5px;
}

.item {
  padding: 5px;
  img {
    max-width: none;
    @include borderRadius();
  }
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nothing {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentScroller {
  &.noTab {
    .top {
      justify-content: center;
      @media all and (max-width: 667px) {
        justify-content: left;
      }
    }
    .arrows {
      position: absolute;
      right: 0;
    }
  }
  &.typeAround {
    box-shadow: none;
    .main {
      min-height: 180px;
      padding: 0;
      overflow: initial;
      .sideArrow {
        position: absolute;
        button {
          color: $grey;
          opacity: 0.8;
          svg {
            font-size: 33px;
          }
        }
        &.arrowPrev {
          left: -35px;
          z-index: 5;
          // bottom: 60px;
          @extend .ex-vertical;
          @media screen and (max-width: 1199px) {
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            left: -10px;
          }
          @media all and (max-width: 991px) {
            left: -5px;
          }
          // @media all and (max-width: 769px) {
          //   background: rgba(0, 0, 0, 0.5);
          //   border-radius: 50%;
          // }
        }
        &.arrowNext {
          right: -35px;
          z-index: 5;
          // bottom: 60px;
          @extend .ex-vertical;
          @media screen and (max-width: 1199px) {
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            right: -10px;
          }
          @media all and (max-width: 991px) {
            right: -5px;
          }
          // @media all and (max-width: 769px) {
          //   background: rgba(0, 0, 0, 0.5);
          //   border-radius: 50%;
          // }
        }
      }
    }
  }
}
