@import "../../../../styles/partials/colors";
@import "../../../../styles/partials/fonts";
@import "../../../../styles/partials/snippets";

#notificationPage {
  .commonAvatar {
    width: auto;
    height: auto;
  }
  .notificationSection {
    @media all and (max-width: 667px) {
      margin-bottom: 0;
      .customContainer {
        padding: 0px;
      }
    }
    .sectionContent {
      background-color: #fff;
      .title {
        padding: 22px 27px;
        @extend .ex-flex;
        @extend .ex-flex-wrap;
        justify-content: space-between;
        border-bottom: 1px solid $grey-light;
        .titleItem {
          color: $black;
          font-weight: 200;
          h5 {
            font-family: $fontMain;
            font-weight: bold;
            color: $primary;
          }
          button {
            font-size: 14px;
            // font-weight: bold;
            color: #212121;
            padding: 0;
            margin: 0;
            border: 0;
            background: transparent;
            color: $black;
            font-weight: bold;
          }
          .delete-noti {
            font-size: 20px;
            margin-left: 10px;
            cursor: pointer;
            margin-bottom: 5px;
          }
        }
      }

      .content {
        .commonUserItem {
          padding: 0;
          a,
          &.follow-request {
            padding: 12px 27px;
            display: flex;
            width: 100%;
            @media all and (max-width: 667px) {
              padding: 15px 10px;
            }
          }

          &.follow-request {
            ._info {
              flex-grow: 1;
            }
          }
          .innerItem {
            .noti-time {
              color: #838383;
              font-size: 12px;
              font-weight: bold;
            }
            a {
              padding: unset;
            }
          }
          .innerItem3 {
            margin-right: 0;
            margin-left: auto;
            .img-wrapper {
              height: 70px;
              width: 70px;
              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 5px;
              }
            }
            .CommonButton-buttonAuto-36 {
              width: 90px;
              height: 30px;
              font-family: "Roboto";
            }
          }
        }
        .seen {
          background-color: #f2f2f2;
          // border-right: 2px solid white;
          // border-left: 2px solid white;
          border-bottom: 1px solid white;
        }
        .no {
          background-color: #f2f2f2;
          // border-right: 2px solid white;
          // border-left: 2px solid white;
          border-bottom: 1px solid white;
        }
      }
    }
  }
}
