@import "../../styles/partials/colors";
@import "../../styles/partials/fonts";

.advancedAvatar {
  text-align: center;
  // .info {
  //   margin-top: 15px;
  // }

  .name {
    text-transform: capitalize;
    display: flex;
    flex-wrap: nowrap;
    h4 {
      font-size: 15px;
      font-weight: 700;
      font-weight: bold;
      text-transform: initial;
    }
  }
  .details {
    margin-top: 5px;
    h6 {
      font-family: $fontSpecial;
      color: $grey;
    }
  }
}
.avatar {
  .info {
    .name {
      h4 {
        font-weight: bold;
        font-size: 20px;
      }
    }
    .description {
      h6 {
        font-size: 14px;
        color: #b3b3b3;
      }
    }
    .details {
      h6 {
        font-size: 14px;
        color: #b3b3b3;
      }
    }
  }
}
