/**
 * ==============================================
 * Dot Revolution
 * ==============================================
 */

.dot-revolution {
  position: relative;

  @include dot;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
  }

  &::before {
    left: 0;
    top: - $dotSpacing;

    @include dot($bgColor: $dotBeforeColor);

    transform-origin: $dotWidth/2 ($dotSpacing + $dotWidth/2);
    animation: dotRevolution 1.4s linear infinite;
  }

  &::after {
    left: 0;
    top: - $dotSpacing*2;

    @include dot($bgColor: $dotAfterColor);

    transform-origin: $dotWidth/2 ($dotSpacing*2 + $dotWidth/2);
    animation: dotRevolution 1s linear infinite;
  }
}

@keyframes dotRevolution {
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotateZ(360deg) translate3d(0, 0, 0);
  }
}
