.logo {
  a {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    @media all and (max-width: 1600px) {
      font-size: 18px;
    }
    @media all and (max-width: 1199px) {
      font-size: 16px;
    }
  }
}

.logoSvg {
  width: 80px;
  margin-bottom: 5px;
}
