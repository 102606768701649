.notification-list {
  @media all and (max-width: 990px) {
    display: none;
  }
  .follow-notification {
    padding: 8px 15px !important;
  }
  .notification-wrapper {
    display: flex;
    .commonAvatar {
      width: 35px;
      height: 35px;
      margin-right: 5px;
    }
    .noti-detail {
      display: block;
      min-width: 220px;
    }
    .img-wrapper {
      height: 50px;
      width: 50px;
      margin-left: 10px;
      justify-content: flex-end;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    .btn {
      border-radius: 20px !important;
      height: 30px !important;
      min-width: 40px !important;
      padding: 5px 10px !important;
      line-height: unset !important;
      width: fit-content !important;
      text-align: center !important;
      font-weight: bold;
      margin: auto;
    }
    .following-btn {
      border: 1px solid #dedede !important;
      background-color: #fff !important;
      color: #212121 !important;
      z-index: 100;
    }
    .follow-btn {
      border: 1px solid #212121 !important;
      background-color: #fff !important;
      color: #212121 !important;
      z-index: 100;
    }
  }
}
