@import "../../../../styles/partials/colors";

#filter-dialog-title {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid $grey-mid;
  align-items: center;

  span {
    padding: 10px 15px;
  }

  ._close {
    border-left: 1px solid $grey-mid;
  }
}

#filter-dialog-message {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  
  .range-wrap {
    width: 100%;
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $grey-mid;

    .range-display {
      color: $black-light;
    }
  }

  .price-sort {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 30px 15px;

    ._title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 15px;
    }

    li {
      margin-bottom: 9px;

      span {
        color: $black-light;
        border-radius: 30px;
        height: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
      }

      &.active {
        
        span {
          color: $primary;
          border: 1px solid $primary;
        }
      }
    }
  }
}

