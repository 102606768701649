.ex-flex{
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.ex-flex-wrap{
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}
.ex-vertical{
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ex-transition{
  -webkit-transition: all .6s ease;
  -moz-transition: all .6s ease;
  -ms-transition: all .6s ease;
  -o-transition: all .6s ease;
  transition: all .6s ease;
}

.ex-transition-medium{
  -webkit-transition: all 1.2s ease;
  -moz-transition: all 1.2s ease;
  -ms-transition: all 1.2s ease;
  -o-transition: all 1.2s ease;
  transition: all 1.2s ease;
}
.ex-transition-large{
  -webkit-transition: all 1.8s ease;
  -moz-transition: all 1.8s ease;
  -ms-transition: all 1.8s ease;
  -o-transition: all 1.8s ease;
  transition: all 1.8s ease;
}