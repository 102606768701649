@import "../../styles/partials/fonts";

.view {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // background: #515151;
  height: calc(100vh - 45px);
  position: relative;

  @media all and (max-width: 500px) {
    height: calc(var(--vh) * 100 - 45px);
  }
}

.offseter {
  height: 100%;
  position: absolute;
  left: 0;
  background-position: right center;
  z-index: 1;
  bottom: 90px;
}

.sliderView {
  // max-width: 1920px;
  width: 100%;
  // height: calc(1366px / (1920/800));
  height: 100%;
  position: relative;
  z-index: 5;
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: 0 center;

  &._parallax {
    background-size: cover;
  }

  &:after {
    content: "";
    position: absolute;
    background: inherit;
    width: 100%;
    height: 100%;
    left: 100%;
  }
}

.wrapper {
  // overflow: hidden;
  width: max-content;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  background-position: 0 center;
  height: calc(100% - 90px);

  @media all and (max-width: 500px) {
    height: calc(var(--vh) * 100 - 90px);
  }

  // @media all and (max-width: 1025px) {
  //   min-height: calc(100vh - 121px);
  // }

  // @media all and (max-width: 992px) {
  //   min-height: calc(100vh - 194px);
  // }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background-position: 0 center;
}

.ready {
  border: none;
}

.track {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  transform: translateX(100%);

  img {
    max-height: calc(100vh - 265px);
    // width: auto;
  }
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}

.__more {
  padding: 15px 0;
  color: #7d7d7d;
}

.controls {
  background: #000;
  padding: 24px 0;
  width: 100%;
  color: #fff;
  height: 90px;
}

.container {
  width: 800px;
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.name {
  font-family: $fontSpecial;
  font-size: 27px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 32px;

  @media all and (max-width: 500px) {
    font-size: 24px;
  }
}

.title {
  text-align: center;
  padding: 0px 9px;
  min-width: 0;

  span {
    font-size: 12px;
    color: #8a8a8a;
    position: absolute;
    width: 100%;
    left: 0;
    top: 26px;
  }
}

.moreDetails {
  font-size: 12px;
  border: 1px solid #707070;
  padding: 13px 45px;
  display: inline-block;
  text-align: center;
  margin-top: 15px;
}

.arrows {
  border: 1px solid #515151;
  border-radius: 30px;
  position: relative;
  align-self: flex-start;
  align-items: center;
  height: 51px;
  width: 200px;
  justify-content: space-around;
  display: flex;
  margin-top: 6px;

  &:after {
    content: "";
    width: 2px;
    height: 30px;
    background: #2b2b2b;
    position: absolute;
  }
}

.left {
  background: url("../../assets/img/ex-slider-left.png") no-repeat;
  min-width: 35px;
  height: 20px;
}

.right {
  background: url("../../assets/img/ex-slider-right.png") no-repeat;
  min-width: 35px;
  height: 20px;
}

.sliderWrap {
  position: relative;
  min-height: calc(100vh - 40px);

  @media all and (max-width: 500px) {
    min-height: calc(var(--vh) * 100 - 40px);
  }
}

.imageModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6000;

  .backdrop {
    background: #232323;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .backdropDetails {
    background: transparentize($color: #000000, $amount: 0.2);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .close {
    color: #6e6e6e;
    font-size: 45px;
    cursor: pointer;
    position: absolute;
    top: -60px;
    right: -10px;
  }

  .imageContainer {
    position: relative;
    width: 450px;
    max-width: 90vw;
  }

  .fullimage {
    position: relative;

    img {
      max-width: calc(100vw - 120px);
      max-height: calc(100vh - 160px);
    }
  }

  ._title {
    font-size: 2em;
    font-family: $fontSpecial;
    margin-bottom: 15px;
    text-align: center;
  }

  .detailsModal {
    background: #fff;
    padding: 30px 15px 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: calc(100vh - 150px);
    width: 100%;
  }
}

.buttons {
  position: fixed;
  top: 56px;
  right: 15px;
  z-index: 3000;
  display: flex;

  > span {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    color: #646464;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #646464;
    transition: all 0.3s ease;
    margin: 0 9px;

    &:hover {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
}

.button {
  border: 2px solid #fff;
  border-radius: 30px;
  padding: 12px 30px;
  background: none;
  color: #fff;
}

.excerptModal {
  width: 600px;
}

// slides

:global(#ex-slide-5ede845ef30c3727337562df) {
  .slideWrap {
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      border: 13px solid #000;
      position: relative;
      box-shadow: 14px 11px 18px 0px rgba(0, 0, 0, 0.45);

      img {
        max-height: calc(100vh - 320px);
      }
    }

    &:nth-child(5) {
      padding: 30px 0 10px 30px;
      margin-right: 0 !important;

      &:after {
        content: "";
        position: absolute;
        height: calc(100% + 26px);
        width: 55px;
        background: #fff;
        top: -13px;
        border-top: 13px solid;
        border-bottom: 13px solid;
        right: -54px;
      }
    }

    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      padding: 30px 0 10px 0;

      &:before {
        content: "";
        position: absolute;
        height: calc(100% + 26px);
        width: 23px;
        background: #fff;
        top: -13px;
        border-top: 13px solid;
        border-bottom: 13px solid;
        left: -21px;
      }

      &:after {
        content: "";
        position: absolute;
        height: calc(100% + 26px);
        width: 55px;
        background: #fff;
        top: -13px;
        border-top: 13px solid;
        border-bottom: 13px solid;
        right: -54px;
      }
    }

    &:nth-child(6),
    &:nth-child(7) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    &:nth-child(8) {
      padding: 30px 30px 10px 0;
      margin-left: 0 !important;

      &:after {
        display: none;
      }
    }
  }
}

.buy {
  border-radius: 30px;
  padding: 12px 15px;
  background: #fff;
  color: #000;
  display: inline-flex;
  border: none;
  margin-left: 6px;

  svg {
    font-size: 21px;
    margin-right: 6px;
  }

  span {
    margin-top: 2px;
  }
}

.bgImageDiv {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;

  img {
    height: 100%;
  }

  .bgImageAfter {
    position: absolute;
    left: 100%;
  }
}

.clickInfo {
  margin-top: 10px;
}
