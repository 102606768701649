@import "../../styles/partials/colors";
@import "../../styles/partials/snippets";
@import "../../styles/partials/fonts";

.artistShowcaseItem {
  .advanceAvatarInline {
    @extend .ex-flex;
    @extend .ex-flex-wrap;
    min-width: 450px;
    @media all and (max-width: 1199px) {
      width: 100%;
      margin-bottom: 30px;
      min-width: 30px !important;
    }
    @media all and (max-width: 667px) {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid $grey-light;
    }
    .commonAvatar {
      width: 70px;
      height: 70px;
      @media all and (max-width: 1199px) {
        height: 60px;
        width: 60px;
      }
      @media all and (max-width: 677px) {
        height: 50px;
        width: 50px;
      }
    }
    .infoContainer {
      width: calc(100% - 73px);
      padding-left: 15px;
      @media all and (max-width: 1199px) {
        width: calc(100% - 90px);
      }
      @media all and (max-width: 677px) {
        width: calc(100% - 60px);
        padding-left: 15px;
      }
      .action {
        margin-top: 15px;
        ul {
          li {
            display: inline-block;
            vertical-align: top;
            margin-bottom: 10px;
            &:not(:last-of-type) {
              margin-right: 10px;
              @media all and (max-width: 677px) {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    .bsb-container {
      display: flex;
      justify-content: space-between;
      .name h4 {
        font-weight: bold;
      }
      h6 {
        color: #bbbbbb;
      }
      .action {
        button {
          font-family: $fontMain;
        }
      }
    }
  }

  .artist-gallery-inline {
    min-width: 0;
    flex-grow: 1;
    @media all and (max-width: 1199px) {
      width: 100%;
    }
    .artist-gallery-inline-image img {
      height: 200px;
      max-width: 400px;
      // max-height: 200px;
      // width: 200px;
      object-fit: cover;
      @media screen and (max-width: 481px) {
        padding: 5px;
      }
    }
  }
  &.full-width-gallery {
    flex-direction: column;
    &.advanceAvatarInline {
      margin-bottom: 21px;
    }
  }
}
