/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */

$leftPos: -9999px;
$x1: - $leftPos - $dotSpacing;
$x2: - $leftPos;
$x3: - $leftPos + $dotSpacing;

.dot-pulse {
  position: relative;
  left: $leftPos;

  @include dot;

  box-shadow: $x2 0 0 -5px $dotColor;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;

    @include dot;
  }

  &::before {
    box-shadow: $x1 0 0 -5px $dotBeforeColor;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }

  &::after {
    box-shadow: $x3 0 0 -5px $dotAfterColor;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
  }
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: $x1 0 0 -5px $dotBeforeColor;
  }

  30% {
    box-shadow: $x1 0 0 2px $dotBeforeColor;
  }

  60%,
  100% {
    box-shadow: $x1 0 0 -5px $dotBeforeColor;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: $x2 0 0 -5px $dotColor;
  }

  30% {
    box-shadow: $x2 0 0 2px $dotColor;
  }

  60%,
  100% {
    box-shadow: $x2 0 0 -5px $dotColor;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: $x3 0 0 -5px $dotAfterColor;
  }

  30% {
    box-shadow: $x3 0 0 2px $dotAfterColor;
  }

  60%,
  100% {
    box-shadow: $x3 0 0 -5px $dotAfterColor;
  }
}
