@import "../../styles/partials/snippets";
@import "../../styles/partials/colors";

.comments-wrap {
  .not-logged-in-comment {
    padding: 15px 0 15px 46px;

    a {
      font-size: 14px;
      color: $grey;
    }
  }
}
.comments {
  &.newComments {
    margin: 16px 0 18px;
  }

  .commonCommentContainer {
    .commonCommentItem {
      @extend .ex-flex;
      align-items: center;
      &:not(:last-of-type) {
        margin-bottom: 18px;
      }
      .commonAvatar {
        height: 36px;
        width: 36px;
        margin-right: 5px;
      }
      .commentField {
        width: calc(100% - 36px);
        padding-left: 0;

        .inner {
          input {
            margin: 0;
            border: 0;
            font-size: 12px;
            line-height: 1.4em;
            width: 100%;
            border: 1px solid $grey-mid;
            border-radius: 10px;
            padding: 12px 15px;
          }
        }
      }
    }
  }

  .commentsViewMore {
    margin-top: 15px;
    text-align: center;
    color: $grey;
    p {
      position: relative;
      display: inline-block;
      padding-right: 24px;
      cursor: pointer;
      svg {
        position: absolute;
        @extend .ex-vertical;
        right: 0;
      }
    }
  }
}
