@import "../../../../styles/partials/colors";

#InitiateFollowsPage {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background: $grey-light;
  overflow: auto;
  padding: 40px 0 60px;
  .commonSubNavSection {
    &.typeCategories {
      .sectionContent {
        -webkit-justify-content: center;
        justify-content: center;
        .subNavItem {
          ul {
            li {
              &:nth-of-type(1) {
                padding-right: 0;
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .artistCollectionContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 10px;
    .artistItem {
      padding: 11px;
      width: 25%;
      // width: 280px;
      text-align: center;
      @media screen and (max-width: 1100px) {
        width: 33.33%;
      }
      @media screen and (max-width: 594px) {
        // width: 200px;
        padding: 10px 5px;
      }
      @media screen and (max-width: 481px) {
        width: 50%;
        // width: 140px;
      }
      .artistInner {
        height: 100%;
        padding: 37px 30px;
        background-color: $white;
        transition: all 0.3s ease;
        @media screen and (max-width: 815px) {
          padding: 27px 20px;
        }
        @media screen and (max-width: 711px) {
          padding: 17px 10px;
        }
        .commonAvatar {
          // height: 200px;
          // width: 200px;
          height: 180px;
          width: 180px;
          margin: auto;
          max-width: 100%;
          @media screen and (max-width: 815px) {
            height: 150px;
            width: 150px;
          }
          @media screen and (max-width: 711px) {
            height: 100px;
            width: 100px;
          }
          @media screen and (max-width: 520px) {
            width: 80px;
            height: 80px;
          }
        }
        .info {
          margin-top: 20px;
          .name {
            h4 {
              @media screen and (max-width: 711px) {
                font-size: 16px;
              }
              @media screen and (max-width: 520px) {
                font-size: 14px;
              }
            }

            h3 {
              color: black;
            }
          }
          .details {
            h6 {
              @media screen and (max-width: 520px) {
                font-size: 10px;
              }
            }
          }
          .description {
            h6 {
              @media screen and (max-width: 520px) {
                font-size: 12px;
              }
            }
          }
        }
      }
      &.selected {
        .artistInner {
          background-color: $primary;
          transition: all 0.3s ease;
          .info {
            color: $white;
            .name {
              h3 {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
  .confirmation-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: $black;
    color: $white;
    padding: 13px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    button {
      margin: 0 15px;
      background: #9a9a9a;
    }
  }
  .mobileSelectSection {
    .filterContainer {
      background-color: #fff;
      display: flex;
      align-items: center;
      .filterItem {
        position: relative;
        width: 50%;
        background-color: #fff;
        padding: 10px 10px;
        &:nth-of-type(1) {
          &:after {
            content: "";
            height: 25px;
            width: 1px;
            background-color: $grey-mid;

            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        svg {
          color: $grey;
          position: absolute;
          right: 7px;
          font-size: 18px;
          top: 50%;
          transform: translateY(-50%);
        }
        ul {
          li {
            font-size: 12px;
            display: block;
            text-align: center;
            color: $grey;
            &:nth-of-type(2) {
              font-size: 14px;
              color: $primary;
            }
          }
        }
      }
    }
  }
}
