@import "../../../styles/partials/fonts";

.wrapper {
  min-height: calc(100vh - 40px);
  display: flex;
  align-items: center;
  background: #fff;
  justify-content: center;
}

.exhibitionBanner {
  // display: flex;
  justify-content: center;
  align-items: center;
}

.exhibitionTitle {
  padding: 25px 0;
  h2 {
    font-size: 36px;
    color: #212121;
    font-weight: bold;
    font-family: "Roboto";
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sliderWrap {
  width: 100%;
  margin-top: 10px;
  .tabHeader {
    padding: 0 16px;
    @media screen and (max-width: 600px) {
      // padding: 0;
      margin-bottom: 20px;
    }
  }

  .reactTabsTabList {
    border-bottom: 0;
    margin: 0 0 10px;
    padding: 0;
  }
}

.slide {
  padding: 15px;
  position: relative;
  // height: 199px;

  @media all and (max-width: 600px) {
    padding: 0 0 15px 0;
  }
}

.arrows {
  border: 1px solid #515151;
  border-radius: 30px;
  position: relative;
  align-self: flex-start;
  align-items: center;
  height: 51px;
  width: 200px;
  justify-content: space-around;
  display: flex;
  margin-top: 6px;
  background: #000;

  &:after {
    content: "";
    width: 2px;
    height: 30px;
    background: #2b2b2b;
    position: absolute;
  }
}

.left {
  background: url("../../../assets/img/ex-slider-left.png") no-repeat;
  width: 35px;
  height: 20px;
}

.right {
  background: url("../../../assets/img/ex-slider-right.png") no-repeat;
  width: 35px;
  height: 20px;
}

.sliderBottom {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 30px;
}

.details {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 35px;
  color: #000;

  &._dark {
    color: #fff;
  }
}

.artistName {
  font-family: $fontSpecial;
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: bold;
  @media all and (max-width: 500px) {
    font-size: 21px;
  }
}

.subtitle {
  font-size: 16px;

  @media all and (max-width: 500px) {
    font-size: 12px;
  }
}

.header {
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #dedede;
  padding-bottom: 30px;
  // margin-bottom: 60px;

  .total {
    border: 2px solid #bdbdbd;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 12px;

    span {
      font-size: 21px;
    }
  }

  // @media all and (max-width: 600px) {
  //   img {
  //     width: 250px;
  //   }
  // }
}

.counter {
  position: absolute;
  right: 10px;
  font-size: 24px;
  top: 15px;

  span {
    font-size: 45px;
  }
}

.inner {
  width: 1500px;
  max-width: 100%;
  padding: 0 90px 70px 90px;

  @media all and (max-width: 1025px) {
    padding: 0px 30px;
  }
}

.aligner {
  width: 90px;

  @media all and (max-width: 600px) {
    display: none;
  }
}
