@import "../../../styles/partials/colors";
@import "../../../styles/partials/snippets";
//#homePage{
//  @media screen and (max-width: 1199px) and (min-width: 480px){
//    margin-right: 0px;
//    padding-right: 0px;
//
//  }
//}
.bannerSection {
  overflow: hidden;
  .bannerSlider {
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }
  @media all and (max-width: 991px) {
    margin-bottom: 0;
  }
}

.categorySection {
  @media screen and (max-width: 991px) {
    padding: 30px 10px 10px 10px;
  }
  .cat {
    display: none;
    @media screen and (max-width: 991px) {
      font-weight: bold;
      display: block;
      padding-bottom: 20px;
    }
  }
  .customContainer {
    @media all and (max-width: 991px) {
      padding: 0;
    }
  }
  .categoryContainer {
    @extend .ex-flex;
    @extend .ex-flex-wrap;
    margin: -7px;
    padding-bottom: 10px;
    @media all and (max-width: 991px) {
      // margin: 0;
      // border-bottom: 1px solid #707070;
      // padding-bottom: 0;
    }
    li {
      width: 25%;
      padding: 7px;
      @media all and (max-width: 991px) {
        width: 50%;
        // padding: 0;
        // border: 0;
        // &:nth-of-type(1),
        // &:nth-of-type(2) {
        //   border-bottom: 1px solid #707070;
        // }
        // &:nth-of-type(1),
        // &:nth-of-type(3) {
        //   border-right: 1px solid #707070;
        // }
      }
      a {
        display: block;
        font-size: 20px;
        padding: 50px 15px;
        border-radius: 5px;
        text-align: center;
        color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        // background-color: $black;
        opacity: 1;
        @media all and (max-width: 1600px) {
          font-size: 16px;
        }
        @media all and (max-width: 1399px) {
          font-size: 20px;
          font-weight: bold;
          padding: 40px 15px;
        }
        // @media all and (max-width: 991px) {
        //   border-radius: 0;
        // }
        @media all and (max-width: 576px) {
          font-size: 14px;
          padding: 17px 15px;
        }
      }
      .cat1 {
        background-image: url("../../../assets/img/work/Painting.png");
      }
      .cat2 {
        background-image: url("../../../assets/img/work/Sculpture.png");
      }
      .cat3 {
        background-image: url("../../../assets/img/work/Photography.png");
      }
      .cat4 {
        background-image: url("../../../assets/img/work/New Media Art.png");
      }
      .cat5 {
        background-image: url("../../../assets/img/work/Printmaking.png");
      }
      .cat6 {
        background-image: url("../../../assets/img/work/Drawing.png");
      }
      .cat7 {
        background-image: url("../../../assets/img/work/Design.png");
      }
      .cat8 {
        background-image: url("../../../assets/img/work/Handicraft.png");
      }
    }
  }
}

.commonVideoSection {
  background-color: $black;
  background-image: url("../../../assets/img/pattern/pattern_large.png");
  background-size: contain;
  background-repeat: repeat;
  img {
    width: 100%;
    height: auto;
  }
  .customContainer.mediumWidthLayout {
    @media all and (max-width: 1399px) {
      max-width: 800px;
    }
    @media all and (max-width: 1199px) {
      max-width: 650px;
    }
    @media all and (max-width: 991px) {
      max-width: 100%;
    }
  }
}

.videoItem {
  padding-bottom: 58%;
  position: relative;
  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.popularWorksSection {
  .customContainer {
    @media all and (max-width: 667px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .popularWorks {
    :global(.item) {
      &:not(:last-child) {
        padding-right: 15px;
      }
    }
  }
}

.followArtistSection {
  .customContainer {
    @media all and (max-width: 667px) {
      padding: 0;
    }
  }

  .content-scroller {
    .main {
      padding: 0px;
      .item {
        text-align: center;
        padding: 30px 40px;
        border-right: 1px solid $grey-light;
        @media all and (max-width: 667px) {
          border-right: 0;
          padding: 20px 15px;
        }
        .commonAvatar {
          width: 210px;
          height: 210px;
          @media all and (max-width: 1600px) {
            width: 195px;
            height: 195px;
          }
          @media all and (max-width: 1399px) {
            width: 162px;
            height: 162px;
          }
          @media all and (max-width: 667px) {
            width: 134px;
            height: 134px;
            margin: auto;
          }
        }
        .info {
          margin-bottom: 18px;
        }
      }
    }
  }
}

.artist {
  a {
    color: $black;
  }
}

// .followArtistSection{
//   :global(.content-scroller) {
//     :global(.main) {
//       padding: 0px;
//       :global(.item) {
//         text-align: center;
//         padding: 30px 40px;
//         border-right: 1px solid $grey-light;
//         :global(.commonAvatar){
//           width: 268px;
//           height: 268px;
//         }
//         :global(.info){
//           margin-bottom: 18px;
//         }
//       }
//     }
//   }
// }

.art-categories {
  @media all and (max-width: 999px) {
    margin-top: -3px;

    .home-section {
      padding: 0;

      .customContainer {
        padding: 0;
      }
    }
  }
}

#bhitta-banner {
  // background: url('../../../assets/img/black_clouds.svg') #000;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .slide-wrapper {
    max-height: 70vh;
    // height: 420px;
    width: 100%;
    @media screen and (max-width: 991px) {
      height: 360px;
    }
    // @media screen and (min-width: 769px) and (max-width: 1024px) {
    //   height: 260px;
    // }
    // @media screen and (min-width: 481px) and (max-width: 768px) {
    //   height: 180px;
    // }
    // @media screen and (min-width: 320px) and (max-width: 480px) {
    //   height: 120px;
    // }
    // @media screen and (max-width: 320px) {
    //   height: 100px;
    // }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: top;
      border: 0;
    }
  }
  .control-dots {
    display: none;
  }
  .customContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .carousel .thumbs-wrapper {
    margin: 20px 70px;
  }
  .carouselThumbnailWrapper {
    padding: 0 70px;
    display: flex;
    justify-content: space-between;

    .thumbCard {
      cursor: pointer;
      padding: 2px;
      border: 2px solid transparent;

      width: 100%;
      max-width: 300px;
      margin: 15px 0;
      display: flex;
    }
    .imageHolder {
      padding: 0;
      margin-right: 10px;
      height: 90px;
      width: 90px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    .detailHolder {
      padding: 10px 0;
      width: calc(100% - 90px);
      h5 {
        color: #212121;
        font-weight: bold;
      }
      h6 {
        color: #212121;
      }
    }

    .selectedThumb {
      // border:2px solid #DEDEDE;
      border-radius: 5px;
      background: #dedede;
    }
  }

  ._highlight {
    background: #dc2121;
    border-radius: 9px;
    padding: 90px;

    img {
      width: 420px;
    }
  }

  //@media all and (max-width: 769px) {
  //  height: calc(100vh - 40px - 64px);
  //}

  @media all and (max-width: 450px) {
    //padding: 30px 0;

    .customContainer {
      height: 100%;
    }

    ._highlight {
      background: #21cfdc;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      padding: 60px 30px;

      img {
        width: 300px;
      }
    }
  }

  a {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
