.UserWorkSection {
  .customContainer {
    padding: 0 80px;
    @media (max-width: 1600px) {
      padding: 0 55px;
    }
    @media (max-width: 1199px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 600px) {
      padding: 0 5px;
    }
  }
  .sectionContent2 {
    display: flex;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    // grid-template-columns: 300px  repeat(auto-fill, 100px);
    // grid-template-columns: minmax(300px, max-content)
    //                repeat(auto-fill, 300px) 32%;
  }
  .showcase-item {
    padding: 2%;
    .artItem .inner .imageContainer {
      border-radius: 5px !important;
    }
  }
}
