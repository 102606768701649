@import "../../styles/partials/colors";
@import "../../styles/partials/snippets";
@import "../../styles/partials/fonts";

#studiosPage .artistShowcaseItem {
  margin-left: 0;
  margin-right: 0;
  padding: 20px 40px;
  border-radius: 5px;
  margin-bottom: 20px;
  @media screen and (max-width: 479px) {
    padding: 20px 20px;
  }
  .advanceAvatarInline {
    @extend .ex-flex;
    @extend .ex-flex-wrap;
    min-width: 380px;
    @media screen and (min-width: 1600px) {
      min-width: 450px !important;
    }
    @media all and (max-width: 1199px) {
      width: 100%;
      margin-bottom: 30px;
      min-width: 30px !important;
    }
    @media all and (max-width: 667px) {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid $grey-light;
    }
    .commonAvatar {
      width: 80px;
      height: 80px;
      @media all and (max-width: 1199px) {
        height: 60px;
        width: 60px;
      }
      @media all and (max-width: 677px) {
        height: 50px;
        width: 50px;
      }
    }
    .infoContainer {
      width: calc(100% - 120px);
      padding-left: 15px;
      a {
        text-decoration: none;
        color: $black;
      }
      h4 {
        font-weight: bold;
      }
      .category h6 {
        color: #bbbbbb;
      }
      .details h6 {
        color: #bbbbbb;
      }
      @media all and (max-width: 1199px) {
        width: calc(100% - 90px);
      }
      @media all and (max-width: 677px) {
        width: calc(100% - 60px);
        padding-left: 15px;
      }
      .action {
        margin-top: 15px;
        ul {
          li {
            display: inline-block;
            vertical-align: top;
            &:not(:last-of-type) {
              margin-right: 20px;
              @media all and (max-width: 677px) {
                margin-right: 5px;
              }
            }
            button {
              font-family: $fontMain;
            }
          }
        }
      }
      .artistDesc {
        max-width: 250px;
        margin-top: 15px;
        color: #bbbbbb;
        p.bio {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .artist-gallery-inline {
    min-width: 0;
    flex-grow: 1;
    @media all and (max-width: 1199px) {
      width: 100%;
    }
    .artist-gallery-inline-image img {
      height: 200px;
      max-width: 400px;
      // max-height: 200px;
      // width: 200px;
      object-fit: cover;
    }
  }
  &.full-width-gallery {
    flex-direction: column;
    &.advanceAvatarInline {
      margin-bottom: 21px;
    }
  }
}
