@import "../../styles/partials/colors";
@import "../../styles/partials/fonts";

.studios-to-follow {
  .studio {
    background: $grey-light;
    padding: 15px 15px 5px 15px;
    height: 100%;
    border-radius: 5px;
    .details {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      // align-items: flex-start;
      align-items: center;
      padding: 24px 0 12px;
      width: 300px;

      // .left {

      .name {
        font-size: 16px;
        font-family: "roboto";
        font-weight: 700;
        color: $black;
      }

      .more-details {
        font-family: $fontMain;
        font-size: 14px;
        color: $grey;
        text-align: center;
        height: 30px;
      }
      // }
      .followButtonContainer {
        margin-top: 15px;
      }
    }

    @media all and (max-width: 480px) {
      background: none;
    }
  }
}
