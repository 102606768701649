@import '../../styles/partials/snippets';
@import '../../styles/partials/colors';

.comments-wrap {

  .not-logged-in-comment {
    padding: 15px 0 15px 3px;

    a {
      font-size: 14px;
      color: $grey;
    }
  }
}
.comments{

  &.newComments{
    margin: 16px 0 18px;
  }

  .commonCommentContainer{
    .commonCommentItem{
      @extend .ex-flex;
      align-items: center;
      &:not(:last-of-type){
        margin-bottom: 18px;
      }
      .commonAvatar{
        height: 36px;
        width: 36px;
      }
      .commentField{
        width: 100%;
        padding-left: 0;
        h5{
            margin-left: 10px;
            font-weight: bold;
        }
        .inner{
          input{
            margin: 0;
            border: 0;
            font-size: 12px;
            line-height: 1.4em;
            width: 100%;
            border: 1px solid $grey-mid;
            border-radius: 0;
            padding: 12px 15px;
          }
        }
      }
    }
  }

  .commentsViewMore{
    margin-top: 15px;
    text-align: left;
    color: #212121;
    font-weight: bolder;
    p{
      position: relative;
      display: inline-block;
      padding-right: 24px;
      cursor: pointer;
      svg{
        position: absolute;
        @extend .ex-vertical;
        right: 0;
      }
    }
  }
}
