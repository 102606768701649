#exhibitions .react-tabs__tab--selected {
  border-radius: 20px;
  border: none;
  padding: 10px 25px;
  background: #212121;
  color: #ffffff;
}
#exhibitions
  .exhibitions_sliderWrap__mw-kg
  .exhibitions_reactTabsTabList__KaFG1 {
  margin: 0px;
  @media screen and (max-width: 600px) {
    margin: 15px -10px;
  }
}
.exhibitions_wrapper__3b-Jr {
  align-items: unset !important;
}
.exhibitions_slide__CRlAN {
  padding: 5px 0 15px 5px;
}
.exhibitions_inner__1pgCk {
  @media screen and (max-width: 991px) {
    padding: 50px 30px;
  }
}

.exhibitions_exhibitionTitle__2sJJk {
  @media screen and (max-width: 991px) {
    padding: 0px;
  }
  h2 {
    font-weight: normal !important;
  }
}
.exhibitions_inner__2vt2X {
  padding: 10px 90px 70px 90px;
  @media (max-width: 1048px) {
    padding: 0px 30px 170px 30px !important;
  }
  @media (max-width: 1024px) {
    padding: 0px 90px 330px 90px !important;
  }
  @media screen and (max-width: 991px) {
    padding: 20px 30px 170px 30px !important;
  }
}
.exhibitions_exhibitionTitle__2luaK {
  padding: 30px 0;
  h2 {
    font-weight: normal !important;
  }
}
.exhi_title {
  padding: 0 16px;
  @media screen and (max-width: 600px) {
    padding: 0;
  }
  .banner {
    height: 420px;
    width: 100%;
    padding: 10px 0px;
    @media screen and (max-width: 992px) {
      height: unset;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .web-banner {
    @media screen and (max-width: 601px) {
      display: none;
    }
  }
  .mobile-banner {
    display: none;
    @media screen and (max-width: 600px) {
      display: block;
    }
  }
}
