/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */

.dot-flashing {
  position: relative;

  @include dot;

  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: - $dotSpacing;

    @include dot($bgColor: $dotBeforeColor);

    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: $dotSpacing;

    @include dot($bgColor: $dotAfterColor);

    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  &.red {

    @include dot($bgColor: $dotBgColorRed);

    animation: dotFlashingRed 1s infinite linear alternate;
    animation-delay: .5s;

    &::before {

      @include dot($bgColor: $dotBeforeColorRed);

      animation: dotFlashingRed 1s infinite alternate;
      animation-delay: 0s;
    }

    &::after {
      left: $dotSpacing;

      @include dot($bgColor: $dotAfterColorRed);

      animation: dotFlashingRed 1s infinite alternate;
      animation-delay: 1s;
    }
  }
}

@keyframes dotFlashing {
  0% {
    background-color: $dotColor;
  }

  50%,
  100% {
    background-color: transparentize($dotColor, 0.8);
  }
}

@keyframes dotFlashingRed {
  0% {
    background-color: $dotColorRed;
  }

  50%,
  100% {
    background-color: transparentize($dotColorRed, 0.8);
  }
}
