@import url("../../../styles/partials/_fonts.scss");

.notfound {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../../../assets/img/404/404.jpg") no-repeat center center
    fixed;
  background-size: cover;
  p {
    letter-spacing: 4px;
    font-size: 30px;
  }
  img {
    height: 150px;
    margin: 30px 0;
  }
  .backHome {
    background-color: black;
    color: white;
    outline: none;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}
