.feedDetails {
  // width: 40%;
  // // width: clamp(400px, 60%, 600px);
  // margin: 50px auto;
  // @media screen and (max-width: 992px) {
  //   width: 70%;
  //   margin: auto;
  // }
  // @media screen and (max-width: 576px) {
  //   width: 100%;
  //   margin: auto;
  // }
  .feedItem .feedContent .imageContainer {
    // max-height: 380px;
    div {
      width: 100%;
      .feed-open {
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .__react_modal_image__modal_content {
    div {
      max-height: unset !important;
    }
    img {
      width: unset !important;
      object-fit: unset !important;
    }
  }
}
