@import "../../../../styles/partials/colors";
@import "../../../../styles/partials/snippets";

.headerContainer {
  display: flex;
  align-items: center;
  & > div {
    padding: 10px 14px;
  }
  .logoContainer {
    flex-grow: 1;
  }

  .toggleContainer {
    border-right: 1px solid $black-super-light;
  }

  .searchContainer {
    border-left: 1px solid $black-super-light;
  }
  .toggleContainer,
  .searchContainer {
    color: #fff;
    line-height: 0;
  }
}

.MuiDrawer-paper-95,
.MuiDrawer-paperAnchorLeft-130,
.MuiDrawer-paper-37 {
  width: 100%;
  background-color: $black !important;
}

.mobileNavContainer {
  background-color: $black;
  .navButtons {
    margin-bottom: 25px;
    padding: 15px 30px 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logout {
      button {
        border: none;
        background: none;
        color: #fff;
      }
    }
    .buttonBlock {
      ul {
        li {
          border-radius: 6px;
          overflow: hidden;
          background-color: #fff;
          a {
            min-width: 117px;
            font-size: 12px;
            color: $black;
            display: inline-block;
            vertical-align: top;
            padding: 12px 16px;
          }
        }
      }
    }
    .linkBlock {
      a,
      svg {
        font-size: 20px;
        color: #fff;
        margin-right: 18px;
      }
    }
  }

  .nav {
    ul {
      li {
        display: block;
        position: relative;
        &:not(:last-of-type) {
          margin-bottom: 40px;
          @media all and (max-width: 667px) {
            margin-bottom: 25px;
          }
        }
        &.userProfile {
          svg {
            position: absolute;
            right: 30px;
          }
          & > span {
            display: flex;
            align-items: center;
            i {
              display: inline-block;
              font-weight: bold;
              font-style: normal;
              &:nth-of-type(1) {
                height: 40px;
                width: 40px;
                border-radius: 100%;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                }
              }
              &:nth-of-type(2) {
                padding-left: 15px;
              }
            }
          }
        }
        &.languageSwitch {
          svg {
            margin-bottom: -8px;
          }
          a,
          button,
          span {
            font-size: 10px;
          }
          & > a,
          & > button,
          & > span {
            text-transform: uppercase;
          }
        }
        a,
        button,
        span {
          padding: 0 30px;
          display: block;
          width: 100%;
          text-align: left;
          font-size: 16px;
          color: #fff;
        }
        ul {
          max-height: 0;
          overflow: hidden;
          transition: all 0.4s ease;
          li {
            &:first-of-type {
              margin-top: 30px;
            }
            &:not(:last-of-type) {
              margin-bottom: 25px;
            }
            a,
            button,
            span {
              font-size: 12px;
              // padding-left: 80px;
              background: transparent;
              border: 0;
            }
          }
        }
        &.menu-showing {
          // margin-bottom: 0px;
          ul {
            max-height: 400px;
            transition: all 0.4s ease;
          }
        }
      }
    }
  }
}
