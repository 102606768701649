$primary: #984B48;
$brown: #D75A4A;

$black: #212121;
$black-mid: #444444;
$black-light: #666666;
$black-super-light: rgba(102, 102, 102, 0.52);

$grey: #BBBBBB;
$grey-mid: #E8E8E8;
$grey-light: #F2F2F2;
$grey-dark: #888;

$white: #FFFFFF;

$yellow-light: #FFFBF2;

$facebookBlue: #4267b2;
