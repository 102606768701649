@import "../../../../styles/partials/colors";
@import "../../../../styles/partials/snippets";

.desktopHeader {
  .logoNavWrapper {
    @extend .ex-flex;
    @extend .ex-flex-wrap;
    justify-content: space-between;
    align-items: center;
    .logoLanguageContainer {
      width: 450px;
      @extend .ex-flex;
      @extend .ex-flex-wrap;
      @media all and (max-width: 1199px) {
        width: 300px;
      }
      .languageContainer {
        & > ul > li {
          position: relative;
          padding-left: 10px;
          padding-right: 25px;
          margin-right: 25px;
          cursor: pointer;
          @media all and (max-width: 1199px) {
            padding-right: 18px;
            margin-right: 18px;
          }
          &:after {
            content: "";
            height: 18px;
            width: 1px;
            background-color: #444444;
            position: absolute;
            right: 0;
            @extend .ex-vertical;
          }
        }
        ul {
          li {
            font-size: 12px;
            font-weight: bold;
            color: #fff;
            span {
              @extend .ex-flex;
              align-items: center;
              position: relative;
              bottom: -4px;
              @media all and (max-width: 1600px) {
                bottom: -2px;
              }
              @media all and (max-width: 1399px) {
                bottom: -1px;
                font-size: 10px;
              }
              svg {
                position: relative;
                height: 15px;
                width: 15px;
                bottom: -1px;
              }
            }
            ul {
              max-height: 0;
              min-width: 100px;
              position: absolute;
              top: 34px;
              right: 0;
              background-color: $black;
              text-align: right;
              overflow: hidden;
              @extend .ex-transition;
              @media all and (max-width: 1399px) {
                top: 29px;
              }
              @media all and (max-width: 1199px) {
                top: 28px;
              }
              li {
                padding: 5px 18px;
              }
            }
            &:hover {
              ul {
                max-height: 100px;
                @extend .ex-transition;
              }
            }
          }
        }
      }
    }
    .navigationProfileContainer {
      @media all and (max-width: 991px) {
        display: none;
      }
      width: calc(100% - 450px);
      @media all and (max-width: 1199px) {
        width: calc(100% - 300px);
      }
      display: flex;
      align-items: center;
      @extend .ex-flex;
      @extend .ex-flex-wrap;
      justify-content: flex-end;
      & > div {
        & > ul {
          & > li {
            position: relative;
            display: inline-block;
            & > a,
            button {
              display: block;
              font-size: 14px;
              min-width: 115px;
              padding: 8px 15px;
              line-height: 30px;
              color: #fff;
              @extend .ex-transition;
              @media all and (max-width: 1600px) {
                font-size: 13px;
                min-width: 106px;
                padding: 8px 10px;
                line-height: 28px;
              }
              @media all and (max-width: 1335px) {
                font-size: 12px;
                min-width: 80px;
              }
              @media all and (max-width: 1237px) {
                min-width: 70px;
              }
              @media screen and (max-width: 1050px) {
                min-width: 55px;
                padding: 6px 15px;
              }
              @media screen and (max-width: 1015px) {
                min-width: 50px;
              }
            }
            & > a {
              text-align: center;
            }
            // &:not(:last-of-type) {
            &:after {
              content: "";
              height: 18px;
              width: 1px;
              background-color: $black-mid;
              position: absolute;
              right: 0;
              @extend .ex-vertical;
            }
            // }
          }
        }
      }

      .navigationContainer {
        ul {
          li {
            &:hover {
              &:after {
                background-color: transparent;
              }
              a {
                background-color: $primary;
                @extend .ex-transition;
              }
            }
          }
        }
      }
      .profileContainer {
        & > ul {
          @extend .ex-flex;
          align-items: center;
          & > li {
            &:nth-of-type(1) > a {
              font-weight: bold;
              background-color: $primary;
              color: #fff;
              transition: background-color 0.5s ease;
              svg {
                padding-left: 2px;
                font-size: 16px;
                margin-left: 4px;
                position: relative;
                bottom: -2px;
                @media all and (max-width: 1199px) {
                  bottom: -1px;
                }
              }
              &:hover {
                background-color: rgb(106, 52, 50);
                transition: background-color 0.5s ease;
              }
            }
          }
        }

        // For Loged In Menu
        ul {
          &.loggedInMenu {
            & > li {
              &:not(:first-of-type) {
                & > a {
                  min-width: 40px;
                  width: 40px;
                  @media all and (max-width: 1199px) {
                    min-width: 30px;
                    width: 30px;
                  }
                  svg {
                    position: relative;
                    bottom: 1px;
                    //@media all and (max-width: 1199px){
                    //  bottom: 2px;
                    //}
                  }
                }
              }
              &:first-of-type {
                &:after {
                  display: none;
                }
              }
              a {
                svg {
                  font-size: 18px;
                  fill: #fff;
                  margin: auto;
                  @media all and (max-width: 1600px) {
                    font-size: 16px;
                  }
                  @media all and (max-width: 1199px) {
                    font-size: 14px;
                  }
                }
              }
              &.userMenu {
                & > a {
                  display: flex;
                  align-items: center;
                  width: fit-content;
                  span {
                    &:nth-of-type(1) {
                      height: 30px;
                      width: 30px;
                      border-radius: 100%;
                      margin-right: 10px;
                      display: inline-block;
                      overflow: hidden;
                      // @media all and (max-width: 1199px) {
                      //   height: 20px;
                      //   width: 20px;
                      // }
                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                      }
                    }
                  }
                }
              }
            }
            .logout-btn {
              color: $primary;
              padding: 8px 15px;
            }
          }

          :global(.notification-list) {
            max-height: 300px;
            overflow: auto;

            li {
              background: $black;
            }

            :global(.notification-status-no) {
              a {
                background: $primary;
                color: #fff;
              }
            }
          }
        }
        .logout {
          .logout-btn {
            border: none;
            background-color: $primary;
            height: 40px;
            color: #fff;
          }
        }
      }
    }
  }
}
