@import "../../styles/partials/fonts";
@import "../../styles/partials/colors";

.contentCardItem {
  padding: 30px;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 15px;
  .main {
    margin-bottom: -15px;
  }
  @media all and (max-width: 677px) {
    padding: 15px;
    border-radius: 0;

    .main {
      margin-bottom: 0;
    }
  }
  &:first-child {
    margin-top: 15px;
  }
  &:last-child {
    margin-bottom: 25px;
  }
  textarea {
    color: #333333;
  }
  .title {
    // display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    small {
      font-size: 12px;
      font-style: italic;
    }
    h5 {
      font-family: $fontMain;
      font-weight: bold;
      color: $primary;
      @media all and (max-width: 991px) {
        margin-bottom: 20px;
      }
    }
    .btns {
      right: 30%;
      background: none;
      color: #984b48;
      font-size: 14px;
      font-weight: 600px;
      border: none;
    }

    // font-family: $fontMain;
    // font-size: 16px;
    // color: $primary;
    // margin-bottom: 30px;
  }
}
