@import "../../styles/partials/fonts";
@import "../../styles/partials/colors";

#responsive-dialog-title {
  font-family: $fontMain;
  font-size: 16px;
  font-weight: 700;
}

#responsive-dialog-message {
  margin: 10px 0px;
}

#responsive-dialog-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;

  > span {
    font-size: 12px;
    margin-right: 21px;
    height: 32px;
    border: 1px solid #dedede;
    width: 100px;
    text-align: center;
    padding-top: 5px;
    border-radius: 20px;
    font-family: "Roboto";
    font-size: 14px;
    left: 0px;
  }
  > button {
    width: 110px;
    padding: 5px !important;
  }
}
