@import "../../../styles/partials/colors";
@import "../../../styles/partials/fonts";
@import "../../../styles/partials/snippets";

#artWorkPage {
  @media all and (max-width: 991px) {
    background-color: #fff;
  }
  .sectionTitle {
    h4 {
      font-weight: bold;
    }
  }
  .artworkButtonWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    // button:first-child{
    //   margin-bottom: 10px;
    //   background: #000000;
    //   color:#FFFFFF;
    //   border: transparent;

    // }
    // button:last-child{

    // background:transparent;
    //  border: 1px solid #e1e1e1;

    // }
    .cartButton {
      margin-bottom: 10px;
      background: #000000;
      color: #ffffff;
      border: transparent;
    }
    .messageButton {
      background: transparent;
      border: 1px solid #e1e1e1;
      width: 100%;
    }

    button {
      padding: 15px 30px;
      font-weight: bolder;
      outline: none;
    }
    .icon {
      position: relative;
      top: 0;
      margin-right: 10px;
      color: #212121;
      font-size: 18px;
    }
  }
  .descriptionWrapper {
    margin-top: 12px;
    padding-bottom: 5px;
    border-bottom: 1px solid #f2f2f2;
    h6 {
      font-weight: bold;
    }
    p {
      padding: 12px 0;
    }
  }

  .commentWrapper {
    margin: 15px 0;
  }
  .artGallerySection {
    background-color: $black;
    // background-image: url(../../../assets/img/pattern/pattern_large.png);
    background-size: contain;
    background-repeat: repeat;
    margin-bottom: 40px;
    .commonProductSlider {
      position: relative;
      padding: 40px 0;
      .art-buttons {
        display: flex;
        position: absolute;
        bottom: 60px;
        right: 60px;
        z-index: 10;
        flex-direction: column;

        .like-art-button {
          margin-top: 15px;
        }

        a {
          color: $black;
        }

        @media all and (max-width: 480px) {
          flex-direction: row;
          right: 15px;

          .like-art-button {
            margin-right: 15px;
          }
        }
      }
      @media all and (max-width: 991px) {
        padding: 0;
      }
    }
  }
  .detialSection {
    @media all and (max-width: 991px) {
      margin-bottom: 0px;
    }
    .sectionContent {
      background-color: #fff;
      article {
        padding: 40px;
        @media all and (max-width: 1199px) {
          padding: 25px 30px;
        }
        @media all and (max-width: 991px) {
          padding: 20px 0;
        }
        &:not(:last-of-type) {
          border-bottom: 1px solid $grey-light;
        }
        @media all and (max-width: 991px) {
          &:first-of-type {
            margin-top: 20px;
            border-top: 1px solid $grey-light;
          }
        }
        &.artNameArticle {
          @extend .ex-flex;
          @extend .ex-flex-wrap;
          // justify-content: space-between;
          display: flex;
          flex-direction: column;
          h2 {
            font-family: $fontSpecial;
            font-weight: bold;
            font-size: 22px;
            letter-spacing: 0px;
          }
        }

        .dimension {
          display: flex;
          span:first-child {
            margin-right: 10px;
          }
        }
        &.arDetailArticle {
          margin-top: 0;
          border-top: 0;
          padding-top: 0;
          .artDetailContainer {
            @extend .ex-flex;
            @extend .ex-flex-wrap;
            margin: -15px;
            .artDetailItem {
              padding: 20px;
              .artDetailBreakdown {
                border: 1px solid $grey-mid;
                padding: 20px 16px;
                @media all and (max-width: 667px) {
                  padding: 0;
                  border: 0;
                }
                .breakdownRow {
                  &:nth-of-type(1) {
                    @media all and (max-width: 667px) {
                      padding-top: 20px;
                      border-top: 1px solid $grey-light;
                    }
                  }
                  &:not(:last-of-type) {
                    margin-bottom: 15px;
                    padding-bottom: 15px;
                    border-bottom: 1px solid $grey-light;
                  }
                  .title {
                    margin-bottom: 13px;
                    h6 {
                      font-family: $fontMain;
                      font-weight: 600;
                    }
                  }
                  .content {
                    ul {
                      @extend .ex-flex;
                      @extend .ex-flex-wrap;
                      margin: -10px -5px;
                      li {
                        padding: 10px 5px;
                        font-size: 12px;
                        a {
                          font-size: 12px;
                        }
                      }
                    }
                    &.categories {
                      ul {
                        li {
                          a {
                            padding: 5px 10px;
                            border-radius: 20px;
                            background-color: $grey-light;
                            color: $black;
                            display: block;
                            &.active {
                              background-color: $black;
                              color: #fff;
                            }
                          }
                        }
                      }
                    }
                    &.dimensions {
                      ul {
                        justify-content: space-between;
                      }
                    }
                  }
                }
              }

              &:nth-of-type(1) {
                width: calc(100% - 340px);
                @media all and (max-width: 667px) {
                  width: 100%;
                }
              }
              &:nth-of-type(2) {
                width: 340px;
                @media all and (max-width: 667px) {
                  width: 100%;
                  padding-top: 0;
                }
              }
            }
          }
        }
        &.similarArtArticle {
          overflow: hidden;
          h4 {
            font-family: $fontMain;
          }
        }
      }
    }
  }

  .artistInfoArticle {
    .advanceAvatarInline {
      margin-bottom: 20px;
    }
  }
  .artistShowcaseItem {
    margin: 0;
    padding: 0;
    .profileContainer {
      width: 100%;
      margin-bottom: 45px;
    }
    .portfolioContainer {
      width: 100%;
    }
  }

  .mobilePriceContainer {
    z-index: 1001;
    position: sticky;
    top: 44px;
    .mobilePriceSection {
      background-color: #fff;
      padding: 20px;
      margin-bottom: 0;
      @media all and (max-width: 667px) {
        padding: 15px 10px;
      }
      .priceContainer {
        display: flex;
        justify-content: space-between;
        h2 {
          line-height: 1em;
          font-family: $fontSpecial;
          font-weight: bold;
          font-size: 25px;
          color: $black;
          @media all and (max-width: 667px) {
            font-size: 20px;
          }
          @media all and (max-width: 667px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .slick-slider {
    .slick-arrow {
      z-index: 1;
      position: absolute;
      top: calc(50% - 50px);
      border: none;
      width: 50px;
      height: 50px;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: transparent;
      text-indent: -9999px;
      &.slick-prev {
        left: 30px;
        background-image: url("../../../assets/img/arrow-prev.png");
      }

      &.slick-next {
        right: 30px;
        background-image: url("../../../assets/img/arrow-next.png");
      }
    }

    .slick-dots {
      position: absolute;
      width: 100%;
      display: flex !important;
      justify-content: center;
      bottom: -35px;
      @media all and (max-width: 991px) {
        bottom: 20px;
      }
      li {
        button {
          background: none;
          border: 1px solid $white;
          border-radius: 100%;
          width: 10px;
          height: 10px;
          margin: 0 5px;
          text-indent: -9999px;
          padding: 0;
        }
        &.slick-active {
          button {
            background: $white;
          }
        }
      }
    }

    .slick-slide {
      .art-slide {
        display: flex !important;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 120px);

        @media all and (max-width: 991px) {
          height: calc(100vh - 130px);
        }
        @media all and (max-width: 667px) {
          height: calc(100vh - 91px);
        }
        img {
          max-height: 100%;
          object-fit: cover;
          @media all and (max-width: 667px) {
            padding: 0 15px;
          }
        }
      }
    }
  }
}
.privacy {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: $grey;
  font-size: 12px;
  margin-top: 20px;
  align-items: center;
}
.privacy .report {
  cursor: pointer;
  text-align: center;
}
.privacy span {
  cursor: pointer;
}
