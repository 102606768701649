.overlapImages {
    display: flex;
    /* flex-direction: row; */
    width: 100px;


}

.overlapImage {
    position: relative;
    /* border: 4px solid #fff;
    border-radius: 50%; */
    overflow: hidden;
    width: 50px;
    height: 50px;

}

.overlapImage:not(:last-child) {
    margin-right: -40px;
}

.overlap-img {
    width: 100%;
    display: block;
}