@import "../../styles/partials/colors";

.art-list {
  .popularItem {
    position: relative;
    .imageContainer {
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      // box-shadow: 0 3px 4px rgba(0, 0, 0, 0.19);
      vertical-align: top;
      // @media all and (max-width: 991px) {
      //   box-shadow: 0 3px 4px rgba(0, 0, 0, 0.07);
      // }
      img {
        height: 300px;
        // max-height: 300px;
        object-fit: cover;
        object-position: center;
        max-width: none;
        vertical-align: top;
        border-radius: 5px;
        min-width: 150px;
        max-width: 600px;
      }
      .lazy-placeholder-2 {
        /* background: #f5f5f5; */
        position: relative;
        /* height: 300px; */
        border-radius: 5px;
        margin-right: 8px;
        width: fit-content;
        // width: 100%;
      }
    }
    .like-art-button {
      position: absolute;
      bottom: 8px;
      height: 40px;
      width: 40px;
      right: 15px;
      background: rgba(255, 250, 250, 0.2);
      border: none;

      svg {
        padding: 4px;
      }
      &.liked {
        background: $primary;
        border-color: $primary;
      }
      &.loading {
        opacity: 0.5;
        transition: all 0.3s ease;
        pointer-events: none;
      }

      &:hover {
        border-width: 0;
        transition: all 0.3s ease;
      }
    }
  }
}

.artDetails {
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
  .artLeft {
    padding: 0;
    .artName {
      font-family: "Lora";
      font-size: 16px;
      font-weight: 700;
    }
    span,
    a {
      font-size: 14px;
      color: #9a9a9a;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}
