@import "../../styles/partials/fonts";
@import "../../styles/partials/colors";
@import "../../styles/partials/snippets";

.commonPageHead {
  position: relative;
  height: 350px;
  width: 100%;
  background-color: #e0e0e0;
  // background-image: url("../../assets/img/pattern/pattern_page_head.png");
  // background-image: url("../../assets/img/pattern/exclusive.jpg");
  text-align: center;
  @extend .ex-flex;
  @extend .ex-flex-wrap;
  align-items: center;
  justify-content: center;
  @media all and (max-width: 1600px) {
    height: 230px;
  }
  @media all and (max-width: 1399px) {
    height: 200px;
  }
  @media all and (max-width: 667px) {
    height: 170px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  h1 {
    // font-family: $fontSpecial;
    position: absolute;
    text-transform: uppercase;
    @extend .ex-vertical;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 43px;
    font-weight: bold;
    color: #fff;
  }

  &.withBackgroundImage {
    background-size: cover;
    background-position: center;
  }
}
