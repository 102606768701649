@import "../../../../styles/partials/colors";
@import "../../../../styles/partials/fonts";

#getStudio-page {
  .getStudioSection {
    .customContainer {
      @media all and (max-width: 667px) {
        padding: 0;
      }
    }
    .sectionContent {
      .sectionTitle {
        h2 {
          font-size: 20px;
          font-weight: bold;
        }
      }
      .contentCardItem {
        &:first-child {
          margin-bottom: 30px;
          @media all and (max-width: 667px) {
            margin-bottom: 15px;
          }
        }
      }

      .formContainer {
        .image-uploader-wrap {
          height: auto;
        }
        h5 {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 10px;
        }
        .formAction {
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
          @media all and (max-width: 667px) {
            flex-wrap: wrap;
          }
          .actionItem {
            @media all and (max-width: 667px) {
              width: 100%;
            }
            &:nth-of-type(1) {
              a {
                display: inline-block;
                font-size: 14px;
                font-weight: bold;
                padding: 11px 33px;
                color: $primary;
                border: 1px solid #ebebeb;
                border-radius: 8px;
                @media all and (max-width: 667px) {
                  display: block;
                  text-align: center;
                }
              }
            }
            &:nth-of-type(2) {
              @media all and (max-width: 667px) {
                text-align: right;
                margin-top: 15px;
              }
              .send-reqst {
                background: $primary;
                color: white;
                border: none;
                border-radius: 20px;
                padding: 10px 25px;
              }
              .send-reqst-disabled {
                background: gray;
                color: white;
                border: none;
                border-radius: 20px;
                padding: 10px 25px;
              }
              .cancel-btn {
                border: none;
                color: #9a9a9a;
                font-size: 14px;
                background: none;
              }
              button {
                &:not(:last-of-type) {
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .commonGidContainer {
    .gridItem {
      &.artistSideBar {
        @media all and (max-width: 991px) {
          display: none;
        }
      }
    }

    .sectionContent {
      padding: 40px 15px;
    }
    .sectionTitle {
      margin-bottom: 10px;
      h2 {
        margin-bottom: 5px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #2ea338;
  color: #eeb230;
  cursor: none;
}
