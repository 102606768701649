@import "partials/fonts";
@import "partials/colors";
@import "partials/snippets";
@import "partials/grid";

/*------------------------------------ Site Wide Starts -----------------------------------*/
* {
  outline: none !important;
}
.addFullHeight {
  min-height: calc(100vh - 45px);
  @media all and (max-width: 1600px) {
    min-height: calc(100vh - 40px);
  }
  @media all and (max-width: 1600px) {
    min-height: calc(100vh - 40px);
  }
  @media all and (max-width: 1199px) {
    min-height: calc(100vh - 37px);
  }
  @media all and (max-width: 991px) {
    min-height: calc(100vh - 44px);
  }
}

.commonGidContainer .gridItem.artistSideBar {
  min-height: calc(100vh - 45px);
  @media all and (max-width: 991px) {
    min-height: 50px;
  }
}

.removeMarginBottom {
  margin-bottom: 0;
}
/*------------------------------------- Site Wide Ends ------------------------------------*/

/*--------------------------------- Header Wrapper Starts ---------------------------------*/
#headerWrapper {
  z-index: 1001;
  width: 100%;
  background-color: $black;
  position: fixed;
  top: 0;
  left: 0;
}
/*--------------------------------- Header Wrapper Starts ---------------------------------*/

/*-------------------------------- Content Wrapper Starts ---------------------------------*/
#contentWrapper {
  padding-top: 45px;
  padding-bottom: 84px;
  @media all and (max-width: 1600px) {
    padding-top: 40px;
    padding-bottom: 83px;
  }
  @media all and (max-width: 1399px) {
    padding-bottom: 80px;
  }
  @media all and (max-width: 1199px) {
    padding-top: 36px;
  }
  @media all and (max-width: 991px) {
    padding-top: 44px;
    padding-bottom: 119px;
  }
  @media all and (max-width: 391px) {
    padding-bottom: 149px;
  }
}
/*--------------------------------- Content Wrapper Ends ----------------------------------*/

/*--------------------------------- Footer Wrapper Starts ---------------------------------*/
/*---------------------------------- Footer Wrapper Ends ----------------------------------*/

/*----------------------------------- Components Starts -----------------------------------*/
.coSmallText {
  font-size: 14px;
}

.commonGidContainer {
  @extend .ex-flex;
  @extend .ex-flex-wrap;
  &.typeHeaderUp {
    .commonPageHead {
      width: calc(100% - 320px);
      position: absolute;
      right: 0;
      @media all and (max-width: 1600px) {
        width: calc(100% - 280px);
      }
      @media all and (max-width: 991px) {
        position: relative;
        width: 100%;
      }
    }
    .gridItem {
      &.mainContent {
        padding-top: 350px;
        @media all and (max-width: 1600px) {
          padding-top: 260px;
        }
        @media all and (max-width: 1399px) {
          padding-top: 200px;
        }
        @media all and (max-width: 991px) {
          padding-top: 0;
        }
      }
    }
  }
  &.typeBox {
    .sectionContent {
      padding: 40px 30px;
      background-color: #fff;
      @media all and (max-width: 667px) {
        padding: 20px 15px;
      }
      .contentCardItem {
        padding: 0;
        .main {
          margin-bottom: 0;
        }
        .title {
          h5 {
            margin-bottom: 10px;
          }
        }
      }
      .styled-input {
        label {
          span {
            display: none;
          }
          textarea,
          input {
            font-size: 12px;
            padding: 18px 16px;
            border: 1px solid #ebebeb;
            border-radius: 8px;
          }
        }
      }
      .sectionTitle {
        margin-bottom: 40px;
        @media all and (max-width: 667px) {
          margin-bottom: 15px;
        }
        h2 {
          font-weight: bold;
          margin-bottom: 20px;
          color: $primary;
          @media all and (max-width: 667px) {
            margin-bottom: 20px;
          }
        }
        p {
          color: $grey;
          font-size: 12px;
        }
      }
    }
  }

  .gridItem {
    &.artistSideBar {
      z-index: 1;
      width: 320px;
      padding: 40px 25px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.24);
      background-color: #fff;
      @media all and (max-width: 1600px) {
        width: 280px;
      }
      @media all and (max-width: 991px) {
        width: 100%;
        min-height: 50px;
        padding: 0px 25px 25px 25px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.04);
      }
      .inner {
        position: -webkit-sticky;
        position: sticky;
        top: 85px;
        @media all and (max-width: 991px) {
          position: relative;
          top: 0;
        }
        .sideBarGroup {
          &:not(:first-of-type) {
            margin-top: 30px;
          }
          .sideGroupContent {
            .sideBarItem {
              margin-bottom: 15px;
              .title {
                // margin-bottom: 20px;
                // text-align: center;
                h6 {
                  color: $primary;
                  font-size: 14px;
                  font-weight: bold;
                }
              }
              button {
                font-family: $fontMain;
                letter-spacing: var(--unnamed-character-spacing-0);
              }
              .styles {
                position: relative;
                width: 100%;
                display: inline-block;
                margin-top: 10px;

                @media screen and (min-width: 481px) {
                  width: auto;
                }
                ul {
                  li {
                    padding: 10px 5px;
                    font-size: 12px;
                    // height: 23px;
                    min-width: 60px;
                    padding: 4px 8px;
                    border-radius: 15px;
                    border: 1px solid #bababa;
                    text-align: center;
                    font-size: 13px;
                    cursor: pointer;
                    display: inline-block;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 10px;
                    margin-right: 5px;
                    color: #b6b6b6;
                    background-color: #212121;
                  }
                  a {
                    color: #fff;
                  }
                }
              }

              .content {
                margin-top: 8px;
                input {
                  opacity: 0;
                  position: absolute;
                  pointer-events: none;
                }
                p {
                  font-family: "Roboto" !important;
                  text-align: left !important;
                  margin-bottom: 0px;
                  &.view-all {
                    width: unset;
                    white-space: pre-wrap;
                    text-overflow: unset;
                    overflow-y: unset;
                    line-break: auto;
                  }
                }
                span {
                  font-weight: bold;
                  cursor: pointer;
                }
              }
            }
          }

          &.profileSideBarGroup {
            .sideGroupContent {
              .sideBarItem {
                text-align: center;
                @media all and (max-width: 991px) {
                  padding-top: 0;
                  margin-top: -80px;
                }
                .commonAvatar {
                  width: 180px;
                  height: 180px;
                  margin: auto;
                  border: 6px solid #fff;
                  @media all and (max-width: 1600px) {
                    height: 160px;
                    width: 160px;
                  }
                  @media all and (max-width: 667px) {
                    height: 140px;
                    width: 140px;
                    border: 4px solid #fff;
                  }
                }
                .info {
                  .name {
                    h3 {
                      font-size: 25px;
                    }
                  }
                }
                .action {
                  padding: 15px 0;
                  margin: 30px 0 20px 0;
                  border-top: 1px solid $grey-mid;
                  border-bottom: 1px solid $grey-mid;
                  @media all and (max-width: 991px) {
                    border-top: 0;
                    border-bottom: 0;
                    padding: 0;
                    margin: 0;
                    margin-top: 16px;
                  }
                  ul {
                    li {
                      display: inline-block;
                      &:not(:last-of-type) {
                        margin-right: 10px;
                      }
                    }
                  }
                  .CommonButton-button-26 {
                    @media (max-width: 1600px) {
                      height: 30px;
                    }
                  }
                  button {
                    border-radius: 20px;
                    height: 30px;
                    width: 100px;
                  }
                  .cart-btn {
                    border: 1px solid #dedede;
                    background: #fff;
                  }
                  .saved-btn {
                    border: 1px solid #212121;
                    background: #fff;
                  }
                }
              }
            }
          }

          &.bioSideBarGroup {
            @media all and (max-width: 991px) {
              max-height: 0px;
              margin-top: 0;
              opacity: 0;
              pointer-events: none;
              transition: all 0.4s ease;
            }
            &.bio-showing {
              max-height: 1000px;
              opacity: 1;
              pointer-events: initial;
              transition: all 0.4s ease;
            }
            .sideGroupContent {
              @media all and (max-width: 991px) {
                padding-top: 25px;
              }
              .sideBarItem {
                .content {
                  p,
                  ul li,
                  a {
                    font-size: 14px;
                    font-family: $fontSpecial;
                    text-align: center;
                  }
                }
              }
            }
          }
        }

        .sideGroupAction {
          display: none;
          @media all and (max-width: 991px) {
            display: block;
            height: 14px;
            padding-top: 35px;
            position: relative;
            text-align: center;
            color: $grey;
            margin: auto;
            svg {
              position: absolute;
              left: 0;
              right: 0;
              margin: auto;
              bottom: -5px;
            }
          }
        }
      }
    }

    &.mainContent {
      width: calc(100% - 320px);
      @media all and (max-width: 1600px) {
        width: calc(100% - 280px);
      }
      @media all and (max-width: 991px) {
        width: 100%;
      }
    }
  }
}

.commonSubNavSection {
  z-index: 10;
  background-color: #fff;
  position: sticky;
  top: 45px;
  @media all and (max-width: 1600px) {
    top: 40px;
  }
  @media all and (max-width: 1199px) {
    top: 36px;
  }
  @media all and (max-width: 991px) {
    top: 44px;
  }
  ul {
    text-align: center;
    @media all and (max-width: 991px) {
      text-align: left;
    }
    li {
      display: inline-block;
      vertical-align: top;
      @media all and (max-width: 1600px) {
        font-size: 14px;
      }
      &:not(:last-of-type) {
        margin-right: 40px;
        @media all and (max-width: 991px) {
          margin-right: 25px;
        }
        @media all and (max-width: 677px) {
          margin-right: 15px;
        }
      }
      span {
        display: block;
        padding: 20px 0;
        @media all and (max-width: 991px) {
          padding: 18px 0;
        }
      }
      a {
        display: block;
        color: $grey;
        &.active {
          color: $black;
        }
      }
    }
  }
  &.typeCategories {
    border-top: 1px solid $grey-light;
    box-shadow: 0px 6px 3px rgba(0, 0, 0, 0.03);
    // position: sticky;
    .sectionContent {
      @extend .ex-flex;
      @extend .ex-flex-wrap;
      justify-content: space-between;
      .subNavItem {
        ul {
          li {
            position: relative;
            &:first-of-type {
              padding-right: 40px;
              @media all and (max-width: 991px) {
                padding-right: 25px;
              }
            }
            span {
              color: $grey;
            }
            .active {
              span {
                font-weight: bold;
                color: $primary;
              }
            }
            &:nth-of-type(1) {
              &:after {
                content: "";
                height: 80%;
                width: 1px;
                background-color: $grey-light;
                position: absolute;
                right: 0;
                @extend .ex-vertical;
              }
            }
          }
        }
      }
    }
  }
  &.typeBoldTransparent {
    background: transparent;
    ul {
      @media all and (max-width: 991px) {
        text-align: center;
      }
      li {
        &:not(:last-of-type) {
          margin-right: 10px;
        }
        a {
          font-weight: bold;
          padding: 0;
          &.active {
            border-bottom: 1px solid $black;
          }
        }
      }
    }
  }
}

.commonDropdown {
  position: relative;
  &.typeLargeSpace {
    ul,
    .dropDownContainer {
      top: 60px;
    }
  }
  &.typeLargeDrop {
    ul,
    .dropDownContainer {
      li,
      .dropDownItem {
        min-width: 320px;
        @media all and (max-width: 1600px) {
          min-width: 260px;
        }
      }
    }
  }
  ul,
  .dropDownContainer {
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 46px;
    padding-top: 8px;
    z-index: 500;
    opacity: 0;
    transition: opacity 0.3s ease;
    @media all and (max-width: 1600px) {
      top: 43px;
    }
    &:after {
      content: "";
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $black;
      position: absolute;
      top: 1px;
      right: 20px;
    }
    .inner {
      max-height: 400px;
      // min-width: 350px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: $grey;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.44);
        -webkit-border-radius: 0px;
        border-radius: 0px;
      }
      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: $grey-light;
        -webkit-box-shadow: inset 0 0 6px $grey-light;
      }
      &::-webkit-scrollbar-thumb:window-inactive {
        background: $grey-light;
      }
    }
    .seeMore {
      background-color: #2b2b2b;
      text-align: center;
      min-width: 80px;
      a {
        display: block;
        color: #fff;
        font-size: 11px;
        padding: 6px 15px;
        text-align: center;
      }
    }
    .seeMoreMessage {
      background-color: #2b2b2b;
      min-width: 80px;
      // text-align: center;
      a {
        display: block;
        color: #fff;
        font-size: 11px;
        padding: 6px 15px;
        text-align: center;
      }
    }
    li,
    .dropDownItem {
      display: block;
      background-color: $black;
      min-width: 250px;
      color: #fff;
      @media all and (max-width: 1600px) {
        min-width: 220px;
      }
      &:not(:last-of-type) {
        margin-right: 0;
        padding-right: 0;
      }
      &:nth-of-type(1):after {
        display: none;
      }
      p {
        font-size: 11px;
        color: $grey;
      }
      button {
        display: block;
        background: transparent;
        border: 0;
        color: #fff;
        text-align: left;
        line-height: 21px;
      }
      span,
      a,
      button {
        width: 100%;
        display: block;
        text-align: left;
        font-size: 14px;
        line-height: 21px;
        padding: 11px 15px;
        color: #fff;
        text-align: left;
        cursor: pointer;
        @media all and (max-width: 1600px) {
          font-size: 12px;
          line-height: 18px;
          padding: 10px 15px;
        }
        @media all and (max-width: 1600px) {
          padding: 8px 15px;
        }
        &:hover {
          background-color: #2b2b2b;
        }
      }
      &:nth-of-type(1) a {
        padding-top: 12px;
      }
      &:not(:last-of-type) {
        border-bottom: 1px solid $black-mid;
      }
    }
    .get-studio {
      background: url("../assets/img/pattern/get_a_studio.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &:hover {
    ul,
    .dropDownContainer {
      pointer-events: initial;
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }
}
.notification-list {
  .inner {
    min-width: 350px;
  }
}
.report-btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  button {
    width: 100px;
  }
}
.report-input {
  margin: 5px 0px 10px 0px;
  border-radius: 5px;
  border: 1px solid grey;
  height: 30px;
  padding: 5px;
}
/*------------------------------------ Components Ends ------------------------------------*/

/*---------------------------------- Slick Slider Starts ----------------------------------*/
/*----------------------------------- Slick Slider Ends -----------------------------------*/
