@import "../../styles/partials/colors";

.image-uploader-wrap {
  background: $grey-light;
  border-radius: 5px;
  padding: 10px;
  // height: 120px;

  .image-uploader {
    // margin: 10px;
    border: 2px dashed $grey;
    padding: 33px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary;
    cursor: pointer;
    font-weight: bold;
  }

  .images-preview {
    display: flex;
    width: max-content;

    .add-image-single {
      min-width: 150px;
      height: 120px;
      background: $white;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .preview {
      border-radius: 10px;
      overflow: hidden;
      height: 120px;
      width: 150px;
      margin-right: 18px;
      position: relative;
      background: $black;

      img {
        width: 100%;
        height: 120px;
        object-fit: cover;
      }

      .cover-image {
        position: absolute;
        // z-index: 5;
        left: 0;
        top: 21px;
        font-size: 12px;
        background: $black;
        border-radius: 0 5px 5px 0;
        padding: 0 15px;
        color: $white;
        box-shadow: 0 3px 3px transparentize($black, 0.5);
      }

      .cover-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: transparentize($black, 0.5);
        padding: 12px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;

        span {
          color: $white;
          border: 1px solid $white;
          border-radius: 5px;
          font-size: 12px;
          padding: 2px 9px;
        }
      }

      .remove-image {
        position: absolute;
        top: 3px;
        right: 3px;
        // z-index: 10;
        background: black;
        border-radius: 50%;
      }

      &:hover {
        .cover-overlay {
          opacity: 1;
          visibility: visible;
          transition: all 0.3s ease;
        }
      }

      &.no-cover {
        .cover-overlay {
          display: none;
        }
      }
    }
  }

  .scrollbar-container {
    &.ps--active-x {
      .images-preview {
        padding-bottom: 24px;
      }
    }

    .ps__rail-x {
      background-color: transparent;
      opacity: 1;

      .ps__thumb-x {
        opacity: 1;
      }

      &:hover,
      &:focus,
      &.ps--clicking {
        .ps__thumb-x {
          height: 6px;
        }
      }
    }
  }

  &.single {
    padding: 0;
    background: none;
    display: flex;
    flex-direction: column;
    .single-preview {
      position: relative;
      display: flex;
      justify-content: center;
      img {
        max-height: 300px;
      }
      .remove-image {
        position: absolute;
        top: 6px;
        right: 6px;
        background: transparentize($black, 0.5);
        border-radius: 50%;
        width: 24px;
        height: 24px;
        text-align: center;
        padding-top: 4px;
      }
    }
    .change-img-btn {
      border: none;
      border-radius: 5px;
      background: transparentize($black, 0.5);
      color: white;
      margin-top: 10px;
      padding: 5px;
    }
  }
}
