@import "../../styles/partials/colors";
@import "../../styles/partials/fonts";
@import "../../styles/partials/snippets";

.commonFeedContainer {
  margin-top: 10px;
  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
  @media all and (max-width: 667px) {
    padding: 0;
    background-color: transparent;
  }
  &.typeNewFeed {
    @media all and (max-width: 667px) {
      background-color: #fff;
      padding: 15px;
    }
    .content {
      textarea {
        padding: 15px;
        font-size: 16px;
        width: 100%;
        height: 130px;
        border: 1px solid $grey-mid;
        border-radius: 8px;
        resize: none;
        @media all and (max-width: 1600px) {
          font-size: 14px;
        }
        @media all and (max-width: 667px) {
          font-size: 12px;
          padding: 10px;
        }
      }

      #post-picture {
        position: relative;
        .cancel-icon {
          position: absolute;
          top: -13px;
          right: -20px;
          z-index: 10;
        }
      }
      #post-video {
        position: relative;
        iframe {
          width: 100%;
          padding: 3px;
          border-radius: 5px;
        }
        .cancel-icon {
          position: absolute;
          top: -13px;
          right: -20px;
          z-index: 10;
        }
      }
    }
    .action {
      text-align: right;
      margin-top: 10px;
      @media screen and (min-width: 581px) and (max-width: 681px) {
        text-align: left;
      }
      @media screen and (max-width: 415px) {
        text-align: left;
      }
      button {
        height: 34px;
        width: 116px;
        font-size: 12px;
        @media screen and (min-width: 581px) and (max-width: 681px) {
          margin-top: 5px;
        }
        &:not(:last-of-type) {
          margin-right: 15px;
        }
      }
    }
    .add-image {
      input {
        display: none;
      }
      span {
        min-width: 130px;
        // padding: 7px 0 4px;
        // height: 30px;
        font-size: 12px;
        font-family: $fontMain;
        border-radius: 30px;
        text-transform: none;
        color: $white;
        background: $primary;
        margin-right: 9px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        // @media all and (max-width: 1600px) {
        height: 34px;
        min-width: 116px;
        padding: 8px 0 6px;
        // }
        @media screen and (max-width: 415px) {
          margin-bottom: 5px;
        }
      }
    }
  }
}

.feedItem {
  border-radius: 8px;
  border: 1px solid $grey-mid;
  background: #fff;
  @media all and (max-width: 667px) {
    border: 0;
    border-radius: 0;
  }
  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
  .feedTitle {
    @extend .ex-flex;
    align-items: center;
    padding: 15px;
    margin-bottom: 5px;
    .commonAvatar {
      height: 60px;
      width: 60px;
      @media all and (max-width: 1600px) {
        height: 50px;
        width: 50px;
      }
    }
    .authorName {
      padding-left: 12px;
      flex-grow: 1;
      a {
        color: $black;
      }
      h4 {
        font-weight: bold;
      }
      p {
        color: $grey;
        font-family: $fontSpecial;
      }
    }
    .dropdown {
      margin-right: -10px;
    }
  }
  .feedContent {
    .imageContainer {
      margin-bottom: 10px;
      display: flex;
      max-height: 900px;
    }
    .imageContainer img {
      width: 100%;
      object-fit: cover;
    }
    a {
      color: black;
      text-decoration: none;
    }
  }
  .editFeedContent {
    .image-uploader-wrap.single {
      width: 100%;
      padding: 5px;
    }
    .imageContainer {
      margin-bottom: 10px;
      display: flex;
      max-height: 360px;
    }
    .imageContainer img {
      object-fit: cover;
    }
  }
  .comments-section {
    padding: 0 16px 16px 16px;
    margin-top: 10px;
    textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid #ededed;
      resize: none;
    }

    .view-comments {
      display: block;
      text-align: center;
      color: $grey;
      @media all and (max-width: 1399px) {
        font-size: 14px;
      }
      @media all and (max-width: 667px) {
        margin-top: 15px;
        font-size: 12px;
      }
    }
  }
}
.textContainer {
  padding: 10px 16px 0 16px;
  p {
    white-space: pre-wrap;
    line-break: auto;
  }
}
.editTextContainer {
  padding: 10px 0 0 0;
}
.viewAllComments {
  padding: 0 16px 5px 16px;
  a {
    color: #a5a5a5;
    cursor: pointer;
    text-decoration: none;
  }
  a:hover {
    color: #a5a5a5;
    cursor: pointer;
  }
}

.commonLike {
  z-index: 0;
  padding: 10px 16px;
  position: relative;

  .inner {
    z-index: 1;
    display: inline-block;
    padding-right: 23px;
    background-color: #fff;
    display: flex;
    align-items: center;
    width: max-content;
    @media all and (max-width: 667px) {
      padding-right: 15px;
    }

    .heartIcon {
      color: #f7434d;
      font-size: 22px;
      cursor: pointer;
    }
    .heartIconUnlike {
      font-size: 22px;
      cursor: pointer;
    }
    .likeBtn {
      margin-left: 15px;
      margin-top: 2px;
    }

    .commentIcon {
      font-size: 22px;
    }
    .icon {
      position: relative;
      margin-right: 14px;
      @media all and (max-width: 667px) {
        margin-right: 8px;
      }
    }
  }
}
.edit-post-body {
  .feedItem {
    .feedTitle {
      padding: 15px 5px;
      h3 {
        font-size: 16px;
      }
    }
  }
  iframe {
    width: 100%;
  }
}
.edit-post-dialog {
  textarea {
    width: 100%;
    height: 50px;
    padding: 9px;
    resize: none;
    border: 1px solid $grey-light;
  }

  .edit-post-dialog-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 12px;
    > span {
      margin-right: 12px;
      font-size: 12px;
    }
  }
}

.showYoutube {
  display: block;
}

.hideYoutube {
  display: none;
}

.youtube-container iframe {
  width: 100%;
  height: 300px;
}

#changeVideoBtn {
  width: 100%;
  color: #ffffff;
  font-size: 12px;
  background: #984b48;
  margin-right: 9px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: none;
  padding: 5px 0;
}

#youtubeUrl {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #e0e0e0;
}
