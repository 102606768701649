@import "../../../styles/partials/colors";
@import "../../../styles/partials/fonts";

#login-page {
  background-image: url("../../../assets/img/banner/login_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .contentSection {
    margin-bottom: 0;
    height: 100%;
    .customContainer {
      height: 100%;
      @media all and (max-width: 667px) {
        padding: 0;
      }
    }
    .sectionContent {
      width: 428px;
      height: 100%;
      max-width: 100%;
      padding: 60px 48px;
      margin-left: auto;
      background-color: #fff;
      @media all and (max-width: 1600px) {
        width: 390px;
        padding: 50px 38px;
      }
      @media all and (max-width: 1399px) {
        width: 365px;
      }
      @media all and (max-width: 1199px) {
        width: 330px;
        padding: 40px 30px;
      }
      @media all and (max-width: 667px) {
        background-color: rgba(255, 255, 255, 0.86);
      }
      @media all and (max-width: 500px) {
        width: 100%;
      }
      .title {
        margin-bottom: 15px;
        h2 {
          font-family: $fontMain;
          font-weight: bold;
        }
        p {
          color: $grey-dark;
          margin-top: 10px;
          @media all and (max-width: 1600px) {
            font-size: 14px;
          }
          @media all and (max-width: 667px) {
            color: #848484;
          }
        }
      }
    }
  }
}

.customForm {
  .formErrors {
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    font-style: italic;
  }
  &.typeLogin {
    .fields {
      .formGroup {
      }
    }
    .formAction {
      margin-top: 20px;
      button {
        background-color: $primary;
        color: #ffff;
        font-family: $fontMain;
        font-size: 16px;
      }
    }
  }
  .fields {
    .formGroup {
      small {
        color: $grey;
      }
      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
      a {
        display: block;
        margin-top: 30px;
        color: $black;
        @media all and (max-width: 1600px) {
          margin-top: 20px;
          font-size: 14px;
        }
        @media all and (max-width: 1199px) {
          margin-top: 15px;
          font-size: 12px;
        }
      }
      input {
        width: 100%;
        padding: 14px 16px;
        border-radius: 6px;
        border: none;
        background: #f6f6f6;
        border: 1px solid #dedede;
        @media all and (max-width: 1600px) {
          font-size: 14px;
          padding: 12px 13px;
        }
        @media all and (max-width: 667px) {
          font-size: 12px;
          padding: 10px 10px;
        }
      }
    }
    .typeForgot {
      font-weight: bold;
    }
  }
  textarea {
    width: 100%;
    height: 180px;
    border: 1px solid $grey;
    resize: none;
    border-radius: 6px;
    @media all and (max-width: 1600px) {
      font-size: 14px;
      padding: 12px 13px;
    }
    @media all and (max-width: 667px) {
      font-size: 12px;
      padding: 10px 10px;
    }
  }
  .formAction {
    margin-top: 15px;
  }
}

.verificationContainer {
  .verificationItem {
    &:not(:last-of-type) {
      margin-bottom: 80px;
    }
  }
}
.customTabHeader {
  margin-bottom: 15px;
  ul {
    li {
      display: inline-block;
      position: relative;
      font-family: $fontMain;
      font-size: 22px;
      font-weight: bold;
      color: $grey;
      @media all and (max-width: 1600px) {
        font-size: 18px;
      }
      @media all and (max-width: 1199px) {
        font-size: 16px;
      }
      &.active {
        color: $black;
      }
      &:not(:last-of-type) {
        margin-right: 40px;
        padding-right: 40px;
        @media all and (max-width: 1600px) {
          margin-right: 30px;
          padding-right: 30px;
        }
        @media all and (max-width: 1600px) {
          margin-right: 15px;
          padding-right: 15px;
        }
        &:after {
          content: "";
          height: 100%;
          width: 1px;
          background-color: $grey;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
}
.customTabContent {
  .customTabItem {
    display: none;
    &.active {
      display: block;
    }
  }
}

.social-logins {
  padding-top: 30px;
  display: flex;
  justify-content: center;

  .facebook-login,
  .google-login {
    &:not(:last-child) {
      margin-bottom: 9px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;
      border: none;
      color: #fff;
      border-radius: 30px;
      width: 120px;
      height: 45px;
      margin-right: 5px;
      svg {
        margin-right: 6px;
        font-size: 24px;
        fill: #fff;
      }

      &:hover {
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.12);
        transition: all 0.3s ease;
      }
    }
  }

  .google-login {
    button {
      background: #d54b3d;
    }
  }

  .facebook-login {
    button {
      background: #3c599a;
    }
  }

  .email-register {
    background: $black;
    color: #fff;
    width: 45px;
    height: 43px;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.content-divider {
  position: relative;
  text-align: center;
  margin-bottom: 15px;

  &:after {
    position: absolute;
    content: "";
    background: #f2f2f2;
    height: 2px;
    width: 45%;
    top: 10px;
    right: 0;
    z-index: 0;
  }

  &:before {
    position: absolute;
    content: "";
    background: #f2f2f2;
    height: 2px;
    width: 45%;
    top: 10px;
    left: 0;
    z-index: 0;
  }

  span {
    position: relative;
    z-index: 1;
    padding: 0 9px;
  }
}

.go-back {
  display: inline-flex;
  margin-bottom: 13px;
  justify-content: center;
  align-items: center;

  span {
    font-weight: bold;
    margin-left: 9px;
  }
}

#register {
  .customTabHeader {
    margin-bottom: 20px;
  }
}
