@import "../../styles/partials/colors";

.content-slider-slick-wrapper {
  .slick-slider {
    min-height: 350px;
    overflow: hidden;
    @media screen and (max-width: 481px) {
      min-height: unset;
    }
  }

  .content-scroller-slick {
    margin-right: 15px;
    @media screen and (max-width: 480px) {
      margin-right: 10px;
    }
    img {
      object-fit: cover;
      height: 300px;
      border-radius: 7px;
    }

    &.studios-to-follow {
      img {
        height: auto;
      }
    }
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    margin-top: 15px;
    li {
      margin: 0 3px;

      button {
        display: block;
        width: 3px;
        height: 3px;
        text-indent: -9999px;
        border: none;
        border-radius: 50%;
        padding: 0;
        background: $black-mid;
      }

      &.slick-active {
        button {
          background: $grey;
        }
      }
    }
  }
}
.slick-slide,
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);

  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
