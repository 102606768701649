@import "../../../styles/partials/colors";
@import "../../../styles/partials/snippets";

#footerWrapper {
  background-color: $black;
  position: absolute;
  bottom: 0;
  width: 100%;
  :global(.customContainer) {
    padding-top: 20px;
    padding-bottom: 20px;
    @media all and (max-width: 991px) {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
  .footerBlock {
    @extend .ex-flex;
    @extend .ex-flex-wrap;
    justify-content: space-between;
    .footerItem {
      &:nth-of-type(1) {
        display: flex;
        @media all and (max-width: 991px) {
          width: 100%;
        }
      }
      &:nth-of-type(2) {
        display: flex;
        align-items: center;
        @media all and (max-width: 991px) {
          margin-top: 20px;
        }
        ul {
          li {
            display: inline-block;
            vertical-align: top;
            color: #fff;
            @media all and (max-width: 991px) {
              padding: 5px 0;
            }
            &:not(:last-of-type) {
              margin-right: 30px;
            }
            a {
              color: inherit;
              @media all and (max-width: 991px) {
                font-size: 10px;
              }
            }
          }
          &:nth-of-type(2) {
            @media all and (max-width: 991px) {
              display: none;
            }
            margin-left: 40px;
            li {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}

.followSocialIcons {
  margin-left: 20px;
  margin-top: 5px;
  cursor: pointer;
}
