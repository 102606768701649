/**
 * ==============================================
 * Dot Typing
 * ==============================================
 */

$leftPos: -9999px;
$x1: - $leftPos - $dotSpacing;
$x2: - $leftPos;
$x3: - $leftPos + $dotSpacing;

.dot-typing {
  position: relative;
  left: $leftPos;

  @include dot;

  box-shadow:
    $x1 0 0 0 $dotBeforeColor,
    $x2 0 0 0 $dotColor,
    $x3 0 0 0 $dotAfterColor;
  animation: dotTyping 1.5s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow:
      $x1 0 0 0 $dotBeforeColor,
      $x2 0 0 0 $dotColor,
      $x3 0 0 0 $dotAfterColor;
  }

  16.667% {
    box-shadow:
      $x1 -10px 0 0 $dotBeforeColor,
      $x2 0 0 0 $dotColor,
      $x3 0 0 0 $dotAfterColor;
  }

  33.333% {
    box-shadow:
      $x1 0 0 0 $dotBeforeColor,
      $x2 0 0 0 $dotColor,
      $x3 0 0 0 $dotAfterColor;
  }

  50% {
    box-shadow:
      $x1 0 0 0 $dotBeforeColor,
      $x2 -10px 0 0 $dotColor,
      $x3 0 0 0 $dotAfterColor;
  }

  66.667% {
    box-shadow:
      $x1 0 0 0 $dotBeforeColor,
      $x2 0 0 0 $dotColor,
      $x3 0 0 0 $dotAfterColor;
  }

  83.333% {
    box-shadow:
      $x1 0 0 0 $dotBeforeColor,
      $x2 0 0 0 $dotColor,
      $x3 -10px 0 0 $dotAfterColor;
  }

  100% {
    box-shadow:
      $x1 0 0 0 $dotBeforeColor,
      $x2 0 0 0 $dotColor,
      $x3 0 0 0 $dotAfterColor;
  }
}
