@import "../../styles/partials/colors";
@import "../../styles/partials/fonts";

#page-top-bar {
  z-index: 1;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.05);
  background: $white;
  position: fixed;
  width: 100%;
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 23px 0;
    @media all and (max-width: 1600px) {
      padding: 18px 0;
    }
    @media all and (max-width: 1199px) {
      padding: 16px 0;
    }
    @media all and (max-width: 667px) {
      padding: 10px 0;
    }
    .title {
      display: flex;
      align-items: baseline;

      h3 {
        font-weight: bold;
        margin-right: 28px;
        @media all and (max-width: 667px) {
          font-size: 14px;
        }
      }

      span {
        font-size: 14px;
        color: $grey;
        @media all and (max-width: 1600px) {
          font-size: 12px;
        }
        @media all and (max-width: 667px) {
          display: none;
        }
      }
    }

    .actions {
      #cancel {
        font-size: 14px;
        margin-right: 15px;
        @media all and (max-width: 1199px) {
          font-size: 12px;
        }
      }
      button {
        background-color: $primary;
        color: #fff;
        font-family: $fontMain;
      }
    }
  }
}
