@import "../../../styles/partials/colors";

#studiosPage {
  .commonPageHead {
    background-image: url("../../../assets//img/pattern/Studios.jpg");
  }
  .commonSubNavSection ul li:not(:last-of-type) {
    margin-right: 20px;
    @media screen and (max-width: 400px) {
      margin-right: 10px;
    }
    @media screen and (max-width: 373px) {
      margin-right: 0px;
    }
  }
  .commonSubNavSection {
    background: #ffffff;
    margin-bottom: 20px;

    .sectionContent {
      @media all and (max-width: 991px) {
        display: flex;
        justify-content: space-between;
      }
    }
    .cat-nav {
      li.active {
        span {
          font-weight: bold;
          color: $primary;
        }
      }
    }
    .artist-nav {
      @media screen and (max-width: 400px) {
        text-align: center;
      }
      @media screen and (max-width: 373px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      li {
        span {
          color: $grey;
          @media all and (max-width: 991px) {
            font-weight: bold;
          }
          @media screen and (max-width: 420px) {
            font-size: 12px;
          }
          @media screen and (max-width: 373px) {
            padding: 5px 0px;
          }
        }

        &.active {
          span {
            color: $black;
            font-weight: bold;
            @media all and (max-width: 991px) {
              color: $primary;
            }
          }
        }
      }
    }

    .artistView {
      display: none;
      padding-left: 18px;
      margin-left: 18px;
      position: relative;
      @media screen and (max-width: 481px) {
        padding-left: 0px;
        margin-left: 0px;
      }
      @media screen and (max-width: 360px) {
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &:after {
        content: "";
        height: 25px;
        width: 1px;
        background-color: $grey-mid;

        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      @media all and (max-width: 991px) {
        display: flex;
        align-items: center;
      }
      li {
        color: $grey;
        &:not(:last-of-type) {
          margin-right: 5px;
          @media screen and (max-width: 373px) {
            margin-right: 0px;
          }
        }
        @media all and (max-width: 667px) {
          svg {
            font-size: 30px;
            margin-left: 15px;
          }
        }
        @media screen and (max-width: 373px) {
          svg {
            font-size: 20px;
          }
        }
        &.active {
          svg {
            fill: $primary;
          }
        }
      }
    }
  }
  .mobileSelectSection {
    display: none;
    @media screen and (max-width: 1060px) {
      display: block;
      z-index: 10;
      position: sticky;
      top: 40px;
    }

    .filterContainer {
      background-color: #fff;
      display: flex;
      align-items: center;
      .filterItem {
        position: relative;
        width: 100%;
        background-color: #fff;
        padding: 10px 10px;
        &:nth-of-type(1) {
          &:after {
            content: "";
            height: 25px;
            width: 1px;
            background-color: $grey-mid;

            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        svg {
          color: $grey;
          position: absolute;
          right: 7px;
          font-size: 18px;
          top: 50%;
          transform: translateY(-50%);
        }
        ul {
          li {
            font-size: 12px;
            display: block;
            text-align: center;
            color: $grey;
            &:nth-of-type(2) {
              font-size: 14px;
              color: $primary;
            }
          }
        }
      }
    }
  }

  .artistSection {
    .customContainer {
      @media all and (max-width: 667px) {
        padding: 0;
      }
    }

    &.grid-showing {
      .sectionContent {
        @media all and (max-width: 991px) {
          display: flex;
          flex-wrap: wrap;
          margin: -10px;
        }
        @media all and (max-width: 667px) {
          margin: 0;
          padding-left: 5px;
          padding-right: 5px;
        }
        .artistShowcaseItem {
          margin-left: 0;
          margin-right: 0;
          @media all and (max-width: 991px) {
            width: calc(50% - 20px);
            margin: 10px;
            padding: 30px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
            text-align: center;
          }
          @media all and (max-width: 667px) {
            width: calc(50% - 10px);
            margin: 5px;
            padding: 25px 25px;
          }
          @media all and (max-width: 490px) {
            // width: 100%;
            padding: 12px;

            button {
              width: auto !important;
            }
          }
          .advanceAvatarInline {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
            .commonAvatar {
              @media all and (max-width: 991px) {
                margin: auto;
              }
            }
            .infoContainer {
              @media all and (max-width: 991px) {
                width: 100%;
                padding-left: 0;
                margin-top: 10px;
              }
              .action {
                ul li {
                  margin-bottom: 10px !important;
                }
              }
              .artistDesc {
                max-width: 250px;
                margin-top: 15px;
                color: #bbbbbb;
                @media screen and (max-width: 991px) {
                  max-width: unset;
                }
              }
            }
          }
          .artist-gallery-inline {
            @media all and (max-width: 991px) {
              display: none;
            }
          }
        }
      }
    }
  }
}

.artist-gallery-inline {
  ._arrow_prev {
    left: -30px !important;
    @media screen and (max-width: 991px) {
      left: -15px !important;
    }
  }

  ._arrow_next {
    right: -30px !important;
    @media screen and (max-width: 991px) {
      right: -15px !important;
    }
  }
  // ._arrow_prev {
  //   left: -20px !important;
  // }

  // ._arrow_next {
  //   right: -20px !important;
  // }
}
