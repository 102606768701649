@import '../../styles/partials/colors';

.artistShowcaseItem{
  display: flex;
  background-color: #fff;
  padding: 40px;
  margin-bottom: 30px;
  margin-left: -15px;
  margin-right: -15px;
  @media all and (max-width: 1199px){
    flex-wrap: wrap;
  }
  @media all and (max-width: 667px){
    padding: 14px 38px;
    margin: 0;
    margin-bottom: 10px;
  }
  .profileContainer{
    display: flex;
    width: 450px;
    padding: 0 15px;
  }
  .portfolioContainer{
    width: calc(100% - 450px);
    padding: 0 15px;
  }
}
