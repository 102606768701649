@import "../../../../styles/partials/colors";
@import "../../../../styles/partials/fonts";

#followingFollowers-page {
  .commonPageHead {
    display: none;
    @media screen and (max-width: 991px) {
      display: block;
    }
  }
  .followers-following {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    @media screen and (max-width: 991px) {
      width: 80%;
    }
    @media screen and (max-width: 481px) {
      width: 100%;
      padding: 0px 5px;
    }
    .contentCardItem {
      padding: 0px 0px;
      .title {
        margin-bottom: unset;
        @media screen and (max-width: 991px) {
          margin-bottom: -10px;
        }
      }
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 8px 20px;
      display: flex;
      border-bottom: 1px solid #e8e8e8;
      @media screen and (max-width: 991px) {
        padding: 0px 10px 5px 10px;
      }

      li {
        padding: 10px 10px;
      }
      li:first-child::after {
        content: "|";
        height: 15px;
        color: $grey;
        margin-left: 20px;
      }
      a {
        font-size: 16px;
        color: $grey;
        &.active {
          // text-decoration: underline;
          color: $primary;
          font-weight: bold;
        }
      }
    }
    .followers {
      padding: 0px 30px;
      .pp-img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: url("https://storage.googleapis.com/tulikaabucketdev/assets/profile-dummy.png");
        margin-right: 15px;
        background-size: cover;
        background-repeat: no-repeat;
      }
      table {
        width: 100%;
        padding: 30px;
        td {
          padding-top: 20px;
          font-weight: bold;
          .dlt-icon {
            border-radius: 50%;
            border: 1px solid $grey;
            padding: 5px;
            height: 30px;
            width: 30px;
            color: $black;
          }
        }
        td:last-child {
          text-align: right;
        }
      }
    }
  }
}
