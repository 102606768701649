$dotWidth: 5px;
$dotHeight: 5px;
$dotRadius: $dotWidth/2;

$dotColor: #fff;
$dotBgColor: $dotColor;
$dotBeforeColor: $dotColor;
$dotAfterColor: $dotColor;

$dotColorRed: #984B48;
$dotBgColorRed: $dotColorRed;
$dotBeforeColorRed: $dotColorRed;
$dotAfterColorRed: $dotColorRed;

$dotSpacing: 10px;
