@import '../../../../styles/partials/colors';
@import '../../../../styles/partials/fonts';
@import '../../../../styles/partials/snippets';

.commonUserContainer {
    .commonUserItem {
        border-bottom: 1px solid $grey-light;

        &.unseen {
            background: #efefef;
        }

        &:hover {
            background-color: $yellow-light;
        }

        a {
            @extend .ex-flex;
            padding: 12px 27px;
            align-items: center;
            color: $black;

            @media all and (max-width: 1199px) {
                padding: 12px 20px;
            }
        }

        .innerItem {
            img {
                height: 36px;
                width: 36px;
            }

            &:nth-of-type(2) {
                padding-left: 13px;

                @media all and (max-width: 1600px) {
                    padding-left: 10px;
                }

                ul {
                    li {
                        line-height: 1.3em;

                        &:nth-of-type(1) {
                            span {
                                font-weight: bold;
                                margin-right: 5px;
                            }

                            @media all and (max-width: 1600px) {
                                font-size: 14px;
                            }

                            @media all and (max-width: 1199px) {
                                font-size: 12px;
                            }

                            @media all and (max-width: 667px) {
                                font-size: 16px;
                            }
                        }

                        &:nth-of-type(2) {
                            margin-top: 2px;
                            font-size: 14px;
                            font-family: $fontSpecial;
                            font-weight: 500;
                            color: $grey;

                            @media all and (max-width: 1199px) {
                                font-size: 12px;
                            }

                            @media all and (max-width: 667px) {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.typeLargeIcon {
        .commonUserItem {
            .innerItem {
                img {
                    height: 50px;
                    width: 50px;

                    @media all and (max-width: 1600px) {
                        height: 45px;
                        width: 45px;
                    }

                    @media all and (max-width: 1600px) {
                        height: 36px;
                        width: 36px;
                    }
                }
            }
        }
    }
}

#messagePage {
    font-family: 'Roboto' !important;

    .messageSection {
        margin-bottom: 0;

        .customContainer {
            @media all and (max-width: 677px) {
                padding: 0;
            }
        }

        .sectionContent {
            background-color: #fff;
            @extend .ex-flex;
            @extend .ex-flex-wrap;

            @media all and (max-width: 667px) {
                overflow: hidden;
            }

            .messageGridItem {
                .title {
                    font-family: $fontMain;
                    background-color: #fff;
                    text-align: center;
                    padding: 21px 16px;
                    border-bottom: 1px solid $grey-mid;
                    color: #000;
                    z-index: 1;

                    @media all and (max-width: 1399px) {
                        padding: 18px 16px;
                    }

                    @media all and (max-width: 1199px) {
                        padding: 16px 16px;
                    }

                    @media all and (max-width: 667px) {
                        padding: 14px 10px;
                    }

                    h5 {
                        font-weight: bold;
                        font-size: 16px;
                        font-family: 'Roboto';
                    }

                    a {
                        color: black;
                    }
                }

                &.messageListBox {
                    z-index: 0;
                    width: 330px;
                    position: fixed;
                    top: 45px;
                    background-color: #fff;
                    height: 100%;

                    @media all and (max-width: 1600px) {
                        top: 40px;
                    }

                    @media all and (max-width: 1199px) {
                        top: 36px;
                        width: 250px;
                    }

                    @media all and (max-width: 991px) {
                        top: 44px;
                    }

                    @media all and (max-width: 667px) {
                        position: relative;
                        width: 100%;
                        min-height: calc(100vh - 44px);
                        top: 0;
                    }

                    .content {
                        max-height: 80vh;
                        overflow-y: scroll;
                    }
                }

                &.messageListBox2 {
                    z-index: 0;
                    width: 330px;
                    position: fixed;
                    top: 45px;
                    background-color: #fff;

                    // height: 100%;
                    @media all and (max-width: 1600px) {
                        top: 40px;
                    }

                    @media all and (max-width: 1199px) {
                        top: 36px;
                        width: 250px;
                    }

                    @media all and (max-width: 991px) {
                        top: 44px;
                    }

                    @media all and (max-width: 667px) {
                        display: none;
                        // position: relative;
                        // width: 100%;
                        // min-height: calc(100vh - 44px);
                        // top: 0;
                    }

                    .content {
                        max-height: 85vh;
                        overflow-y: scroll;
                    }
                }

                &.messageBox {
                    z-index: 2;
                    width: 100%;
                    margin-left: 330px;
                    border-left: 2px solid $grey-mid;
                    background-color: #fff;

                    @media all and (max-width: 1199px) {
                        margin-left: 250px;
                    }

                    @media all and (max-width: 667px) {
                        z-index: 99;
                        width: 100%;
                        position: fixed;
                        top: 40px;
                        margin-left: 0;
                        opacity: 1;
                        // pointer-events: none;
                        transition: all 0.3s ease;

                        &.box-showing {
                            opacity: 1;
                            pointer-events: initial;
                            transition: all 0.3s ease;
                        }
                    }

                    #messages-blank-state {
                        align-self: center;
                        width: 100%;
                        text-align: center;
                    }

                    // .title{
                    //   position: relative;
                    //   position: sticky;
                    //   top: 45px;
                    //   @media all and (max-width: 1600px){
                    //     top: 40px;
                    //   }
                    //   @media all and (max-width: 1199px){
                    //     top: 36px;
                    //   }
                    //   @media all and (max-width: 991px){
                    //     top: 44px;
                    //   }
                    //   @media all and (max-width: 667px){
                    //     width: 100%;
                    //     position: fixed;
                    //     top: 44px;;
                    //   }
                    //   h5{
                    //     padding: 0 35px;
                    //     font-family: 'Roboto';
                    //   }

                    //   .deleteIcon, .backIcon{
                    //     cursor: pointer;
                    //     position: absolute;
                    //     top: 50%;
                    //     transform: translateY(-50%);
                    //     // color: $grey;
                    //     svg{
                    //       font-size: 22px;
                    //       @media all and (max-width: 1399px){
                    //         font-size: 20px;
                    //       }
                    //     }
                    //   }
                    //   .backIcon{
                    //     left: 20px;
                    //     // display: none;
                    //     @media all and (max-width: 667px){
                    //       display: block;
                    //     }
                    //   }
                    //   .deleteIcon{
                    //     right: 20px;
                    //   }
                    // }
                    // .messageThread{
                    //   @extend .ex-flex;
                    //   justify-content: space-between;
                    //   flex-direction: column;
                    //   .messages{
                    //     padding: 18px;
                    //     @media all and (max-width: 667px){
                    //       height: 100vh;
                    //       overflow-y: scroll;
                    //       overflow-x: hidden;
                    //       margin-top: 70px;
                    //       padding-bottom: 80px;
                    //     }
                    //     .messageItem{
                    //       &:not(:last-of-type){
                    //         margin-bottom: 10px;
                    //       }
                    //       .inner{
                    //         @extend .ex-flex;
                    //         .commonAvatar{
                    //           height: 36px;
                    //           width: 36px;
                    //         }
                    //         .chatBox{
                    //           max-width: 55%;
                    //           margin-left: 20px;
                    //           @media all and (max-width: 667px){
                    //             max-width: 70%;
                    //             margin-left: 10px;
                    //           }
                    //           .chatItem{
                    //             &:not(:last-of-type){
                    //               margin-bottom: 10px;
                    //             }
                    //             .itemInner{
                    //               line-height: 1.25em;
                    //               padding: 8px 15px 10px;
                    //               border-radius: 18px;
                    //               background-color: $grey-mid;
                    //               display: inline-block;
                    //               vertical-align: top;
                    //               @media all and (max-width: 1600px){
                    //                 font-size: 14px;
                    //               }
                    //               @media all and (max-width: 1199px){
                    //                 font-size: 12px;
                    //               }
                    //               @media all and (max-width: 667px){
                    //                 line-height: 1.5em;
                    //               }
                    //             }
                    //           }
                    //         }
                    //       }

                    //       &.dir_right{
                    //         .inner{
                    //           justify-content: flex-end;
                    //           .commonAvatar{
                    //             display: none
                    //           }
                    //           .chatBox{
                    //             margin-left: 0;
                    //             .chatItem{
                    //               .itemInner{
                    //                 background-color: $primary;
                    //                 color: #fff;
                    //               }
                    //             }
                    //           }
                    //         }
                    //       }
                    //     }
                    //   }
                    //   .newmessages{
                    //     position: sticky;
                    //     bottom: 0;
                    //     padding: 14px 14px 12px;
                    //     background-color: $grey-mid;
                    //     @media all and (max-width: 667px){
                    //       position: fixed;
                    //       width: 100%;
                    //       bottom: 63px;
                    //     }
                    //     form{
                    //       @extend .ex-flex;
                    //       input{
                    //         -webkit-flex-grow: 1;
                    //         flex-grow: 1;
                    //         border-radius: 30px;
                    //         border: 1px solid $grey-mid;
                    //         padding: 8px 12px;
                    //         margin-right: 15px;
                    //         @media all and (max-width: 667px){
                    //           font-size: 12px;
                    //         }
                    //       }
                    //     }
                    //   }
                    // }
                }

                .content {
                    // max-height: 100vh;
                    // overflow-y: scroll;
                    .messages-container {
                        display: flex;
                        align-items: flex-start;
                        padding: 10px;
                        min-height: fit-content;
                        border-bottom: 1px solid $grey-light;

                        .innerItem {
                            img {
                                height: 50px;
                                width: 50px;
                                border-radius: 50%;
                            }

                            .msg-inbox {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-width: 100px;

                                @media screen and (max-width: 1199px) {
                                    max-width: 95px;
                                }

                                @media screen and (max-width: 667px) {
                                    max-width: 130px;
                                }

                                // @media screen and (max-width: 350px) {
                                //   max-width: 35px;
                                // }
                            }

                            .inbox-name {
                                font-weight: bold;
                                color: #212121;
                            }

                            .inbox-message {
                                font-size: 12px;
                                display: flex;

                                .msg-time {
                                    margin-left: 5px;
                                }
                            }

                            .unread {
                                color: #212121;
                                font-weight: bold;
                            }

                            .read {
                                color: #9a9a9a;
                            }
                        }
                    }

                    .messages-container > *:last-child {
                        margin-top: 5px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}

// .messageThread .messages {
//   overflow: hidden;
// }
.scroll-view {
    @media screen and (max-width: 667px) {
        overflow-x: hidden;
    }
}

.messageBox,
.messages {
    min-height: 75vh;

    @media screen and (min-width: 1600px) {
        min-height: 90vh;
    }

    // overflow-y: auto;
}

#message-box {
    .inbox-open {
        .title {
            position: relative;
            position: sticky;
            top: 45px;

            @media all and (max-width: 1600px) {
                top: 40px;
            }

            @media all and (max-width: 1199px) {
                top: 36px;
            }

            @media all and (max-width: 991px) {
                top: 44px;
            }

            @media all and (max-width: 667px) {
                width: 100%;
                position: fixed;
                top: 44px;
            }

            h5 {
                padding: 0 35px;
                font-family: 'Roboto';
            }

            .deleteIcon,
            .backIcon {
                cursor: pointer;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                // color: $grey;
                a {
                    color: black;
                }

                svg {
                    font-size: 22px;

                    @media all and (max-width: 1399px) {
                        font-size: 20px;
                    }
                }
            }

            .backIcon {
                left: 20px;

                // display: none;
                @media all and (max-width: 667px) {
                    display: block;
                }
            }

            .deleteIcon {
                right: 20px;
            }
        }

        .load-more {
            margin-top: 30px;
            text-align: center;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
        @media all and (max-width: 667px) {
            .load-more {
                margin-top: 80px;
            }
        }

        .content {
            .messageThread {
                @extend .ex-flex;
                justify-content: space-between;
                flex-direction: column;

                .messages {
                    padding: 18px;

                    @media all and (max-width: 667px) {
                        height: 110vh;
                        // overflow-y: scroll;
                        // overflow-x: hidden;
                        margin-top: 20px;

                        // margin-top: 70px;
                        //padding-bottom: 80px;
                        padding-bottom: 400px;
                        .messages{
                            overflow-y: scroll;
                        }
                    }

                    // @media screen and (max-width: 629px) {
                    //     padding-bottom: 120px
                    // }

                    // @media screen and (max-width: 580px) {
                    //     // padding-bottom: 45%;
                    // }

                    // @media screen and (max-width: 490px) {
                    //     padding-bottom: 90%;
                    // }

                    // @media screen and (max-width: 445px) {
                    //     padding-bottom: 90%;
                    // }

                    // @media screen and (max-width: 400px) {
                    //     padding-bottom: 90%;
                    // }

                    // @media screen and (max-width: 370px) {
                    //     padding-bottom: 90%;
                    // }

                    // @media screen and (max-width: 340px) {
                    //     padding-bottom: 90%;
                    // }

                    // @media screen and (max-width: 315px) {
                    //     padding-bottom: 90%;
                    // }

                    // @media screen and (max-width: 280px) {
                    //     padding-bottom: 90%;
                    // }

                    .receiver-img {
                        height: 36px;
                        width: 36px;
                        border-radius: 50%;
                        margin-right: 5px;
                    }

                    .message-contents {
                        margin-bottom: 10px;
                        display: flex;

                        .msg {
                            border-radius: 20px;
                            width: fit-content;
                            max-width: 50%;
                            height: fit-content;
                            padding: 10px;
                            margin-left: 10px;

                            .lazy-placeholder-message {
                                background-image: url('../../../../assets//img/skeleton-img.jpg');
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;
                                position: relative;
                                border-radius: 5px;
                                width: 154px;
                                max-height: 400px;
                                max-width: 100%;
                                min-height: 50px;
                            }

                            .img-msg {
                                max-height: 400px !important;
                                max-width: 100%;
                                min-height: 50px;
                                width: 154px;
                                border-radius: 5px;
                                object-fit: cover;
                            }

                            .__react_modal_image__modal_container {
                                top: 43px !important;
                            }
                        }

                        .msg.sent.text {
                            background-color: $primary;
                            color: #fff;
                        }

                        .msg.received.text {
                            background-color: #f2f2f2;
                        }

                        .msg.sent.image {
                            background: none;
                        }

                        .message.received.image {
                            background: none;
                        }
                    }

                    .message-contents.sent {
                        justify-content: flex-end;
                    }

                    .messageItem {
                        &:not(:last-of-type) {
                            margin-bottom: 10px;
                        }

                        .inner {
                            @extend .ex-flex;

                            .commonAvatar {
                                height: 36px;
                                width: 36px;
                            }

                            .chatBox {
                                max-width: 55%;
                                margin-left: 20px;

                                @media all and (max-width: 667px) {
                                    max-width: 70%;
                                    margin-left: 10px;
                                }

                                .chatItem {
                                    &:not(:last-of-type) {
                                        margin-bottom: 10px;
                                    }

                                    .itemInner {
                                        line-height: 1.25em;
                                        padding: 8px 15px 10px;
                                        border-radius: 18px;
                                        background-color: $grey-mid;
                                        display: inline-block;
                                        vertical-align: top;

                                        @media all and (max-width: 1600px) {
                                            font-size: 14px;
                                        }

                                        @media all and (max-width: 1199px) {
                                            font-size: 12px;
                                        }

                                        @media all and (max-width: 667px) {
                                            line-height: 1.5em;
                                        }
                                    }
                                }
                            }
                        }

                        &.dir_right {
                            .inner {
                                justify-content: flex-end;

                                .commonAvatar {
                                    display: none;
                                }

                                .chatBox {
                                    margin-left: 0;

                                    .chatItem {
                                        .itemInner {
                                            background-color: $primary;
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .message-user-status {
                        text-align: center;
                        margin-bottom: 10px;

                        span {
                            color: $black-light;
                            font-size: 12px;
                            text-align: center;
                            font-family: 'Roboto';
                        }
                    }
                    .new-message{
                        text-align: center;
                        color: red;
                        position: relative;
            
                        &:after{
                          content: "";
                          display: block;
                          width: 40%;
                          height: 2px;
                          background:red;
                          right: 0;
                          top: 50%;
                          position: absolute;
                        }
            
                        &::before{
                        content: "";
                        display: block;
                        width: 40%;
                        height: 2px;
                        background:red;
                        left: 0;
                        top: 50%;
                        position: absolute;
                      }
                      }
                      .message-left,.message-join{
                        text-align: center;
                      }
                }
            }

            #post-picture {
                position: relative;

                @media screen and (max-width: 667px) {
                    bottom: 262px;
                }

                img {
                    border-radius: 5px;
                    object-fit: contain;
                    min-height: 50px;
                }

                .cancel-icon {
                    position: absolute;
                    margin-top: -16px;
                    margin-left: -2px;

                    &:hover {
                        color: red;
                    }
                }
            }
        }

        .newmessages {
            position: sticky;
            bottom: 0;
            padding: 14px 14px 12px;
            background-color: #fff;

            @media all and (max-width: 667px) {
                position: fixed;
                width: 100%;
                bottom: 58px;
            }

            form {
                @extend .ex-flex;

                input {
                    -webkit-flex-grow: 1;
                    flex-grow: 1;
                    border-radius: 30px;
                    border: 1px solid $grey-mid;
                    padding: 8px 12px;
                    margin-right: 8px;
                    font-size: 16px;

                    // height: 40px;
                    @media all and (max-width: 667px) {
                        font-size: 12px;
                    }
                }

                .add-image {
                    input {
                        display: none;
                    }

                    span {
                        border-radius: 50%;
                        background: $primary;
                        margin-right: 9px;
                        display: inline-block;
                        cursor: pointer;
                        padding: 6px;
                        // margin: auto;
                    }
                }

                .img-btn {
                    background-color: $primary;
                    border: none;
                    border-radius: 50%;
                    color: #fff;
                    height: 20px;
                    width: 20px;
                    margin: auto;
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                }

                .send-btn {
                    color: $primary;
                    border: none;
                    background: none;
                }
            }
        }
    }
}

#responsive-dialog-title {
    font-family: $fontMain;
    font-size: 16px;
    font-weight: 700;
}

#responsive-dialog-message {
    margin: 10px 0px;
}

#responsive-dialog-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 5px;

    > span {
        font-size: 12px;
        margin-right: 21px;
        height: 32px;
        border: 1px solid #dedede;
        width: 100px;
        text-align: center;
        padding-top: 5px;
        border-radius: 20px;
        font-family: 'Roboto';
        font-size: 14px;
        left: 0px;
    }

    > button {
        width: 110px;
        font-size: 14px;
        height: 32px;
        padding: 5px !important;
    }
}

.MuiDialog-paperWidthSm-18 {
    max-width: 280px !important;
    padding: 20px !important;
}
